<main id="main-container">
  <div class="content">
    <div class="row">
      <div class="col-lg-3 d-none d-lg-block">
        <app-sidebar-left [isProfileVisible]="false"></app-sidebar-left>
      </div>

      <div class="col-lg-6">
        <div class="block bg-profile-card">
          <div class="row">
            <div class="col-md-4 text-center py-3">
              <div *ngIf="!isChangeImage" class="profile-img-wrapper">
                <img *ngIf="user?.avatar" [src]="user.avatar" alt="users profile image"
                     class="img-avatar img-avatar-thumb">
                <app-spinner *ngIf="!user?.avatar"></app-spinner>
              </div>
              <div *ngIf="isChangeImage" class="px-4">
                <app-file-upload *ngIf="!isImagePreview" [formControl]="imageControl"
                                 [hasInstructions]="false"></app-file-upload>
                <div *ngIf="isImagePreview" class="profile-img-wrapper">
                  <img [src]="previewImageSrc" alt="users profile image" class="img-avatar img-avatar-thumb">
                </div>
              </div>
              <p *ngIf="isOwnProfile && session.isLoggedIn" class="text-center mb-0 mt-2">
                <button (click)="this.isChangeImage = true" *ngIf="!isChangeImage" class="btn btn-primary btn-sm"
                        type="button">
                  Uredi sliko
                </button>
                <span *ngIf="isChangeImage">
                  <i (click)="handleImageSelect(false)" class="fa fa-close fa-lg red-text pointer"></i>
                  <i (click)="handleImageSelect(true)" *ngIf="isImagePreview"
                     class="fa fa-check fa-lg green-text pointer ml-2"></i>
                </span>
              </p>
            </div>
            <div class="col-md-4 text-center">
              <div class="profile-highlight d-flex align-items-center flex-column h-100 justify-content-center">
                <p class="profile-highlight-title">
                  Točk na lestvici
                </p>
                <h1 class="profile-highlight-value">
                  {{user?.points}}
                </h1>
              </div>
            </div>
            <div class="col-md-4 text-center">
              <div class="profile-highlight d-flex align-items-center flex-column h-100 justify-content-center">
                <p class="profile-highlight-title">
                  Trenutno mesto
                </p>
                <h1 class="profile-highlight-value">
                  #{{user.position}}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div class="block">
          <ul class="nav nav-tabs nav-tabs-block js-tabs-enabled" role="tablist">
            <li class="nav-item">
              <a (click)="chooseTab(tabs.Posts)" [ngClass]="{'active': chosenTab === tabs.Posts}" class="nav-link">Objave</a>
            </li>
            <li class="nav-item">
              <a (click)="chooseTab(tabs.Comments)" [ngClass]="{'active': chosenTab === tabs.Comments}"
                 class="nav-link">Komentarji</a>
            </li>
            <li class="nav-item">
              <a (click)="chooseTab(tabs.Activity)" [ngClass]="{'active': chosenTab === tabs.Activity}"
                 class="nav-link">Aktivnosti</a>
            </li>
            <li class="nav-item">
              <a (click)="chooseTab(tabs.About)" [ngClass]="{'active': chosenTab === tabs.About}" class="nav-link">O
                meni</a>
            </li>
            <li *ngIf="isOwnProfile && session.isLoggedIn" class="nav-item ml-auto">
              <a (click)="chooseTab(tabs.Edit)" [ngClass]="{'active': chosenTab === tabs.Edit}" class="nav-link">Uredi
                Profil <i class="si si-settings"></i></a>
            </li>
          </ul>
          <div class="block-content tab-content overflow-hidden">
            <div *ngIf="chosenTab === tabs.Posts" [ngClass]="{'active show': chosenTab === tabs.Posts}"
                 class="tab-pane fade"
                 role="tabpanel">
              <ng-container *ngIf="posts.length; else noPosts">
                <app-post *ngFor="let post of posts; let i = index;" [post]="post"></app-post>
              </ng-container>
              <ng-template #noPosts><p>Uporabnik še nima objav.</p></ng-template>
            </div>
            <div *ngIf="chosenTab === tabs.Comments" [ngClass]="{'active show': chosenTab === tabs.Comments}"
                 class="tab-pane fade active show" role="tabpanel">
              <ng-container *ngIf="comments.length; else noComments">
                <app-comment *ngFor="let comment of comments; let i = index;" (report)="reportComment($event, reportableType.Comment)" [comment]="comment"></app-comment>
              </ng-container>
              <ng-template #noComments><p>Uporabnik še nima komentarjev.</p></ng-template>
            </div>
            <div *ngIf="chosenTab === tabs.Activity" [ngClass]="{'active show': chosenTab === tabs.Activity}"
                 class="tab-pane fade"
                 role="tabpanel">
              <ng-container *ngIf="activities.length; else noActivities">
                <app-activity *ngFor="let activity of activities; let i = index;" [activity]="activity"></app-activity>
              </ng-container>
              <ng-template #noActivities><p>Uporabnik še nima aktivnosti.</p></ng-template>
            </div>
            <div *ngIf="chosenTab === tabs.About" [ngClass]="{'active show': chosenTab === tabs.About}"
                 class="tab-pane fade"
                 role="tabpanel">
              <h4 class="font-w400">O meni</h4>
              <p *ngIf="user.about; else noAbout">
                {{user.about}}
              </p>
              <ng-template #noAbout><p>Uporabnik še ni vnesel opisa</p></ng-template>
            </div>
            <div *ngIf="isOwnProfile && session.isLoggedIn && chosenTab === tabs.Edit" [ngClass]="{'active show': chosenTab === tabs.Edit}"
                 class="tab-pane fade"
                 role="tabpanel">
              <form [formGroup]="changePasswordForm" class="js-validation-signin">
                <div class="block-header p-0">
                  <h3 class="block-title">Spremeni geslo</h3>
                </div>
                <div class="block-content px-0">
                  <div class="form-group row">
                    <div class="col-12">
                      <label for="oldPassword">Staro geslo</label>
                      <input class="form-control" formControlName="oldPassword" id="oldPassword" name="oldPassword"
                             type="password">
                      <span *ngIf="getError(changePasswordForm, 'oldPassword')" class="error-message">Vnesite veljavno geslo.</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-12">
                      <label for="password">Geslo</label>
                      <input class="form-control" formControlName="password" id="password" name="password"
                             type="password">
                      <span *ngIf="getError(changePasswordForm, 'password')" class="error-message">Vnesite veljavno geslo.</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-12">
                      <label for="passwordConfirmation">Ponovite geslo</label>
                      <input class="form-control" formControlName="passwordConfirmation" id="passwordConfirmation"
                             name="passwordConfirmation"
                             type="password">
                      <span *ngIf="getPasswordError()" class="error-message">Gesli se ne ujemata.</span>
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <div class="col-sm-12 text-sm-center">
                      <button (click)="changePassword()" [disabled]="changePasswordForm.invalid" class="btn btn-alt-primary"
                              type="submit">
                        <i class="si si-lock mr-10"></i> Spremeni geslo
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              <form [formGroup]="changeNameForm" class="js-validation-signin">
                <div class="block-header p-0">
                  <h3 class="block-title">Spremeni prikazano ime na portali izklop</h3>
                </div>
                <div class="block-content px-0">
                  <div class="form-group row">
                    <div class="col-12">
                      <label for="name">Novo ime za prikaz</label>
                      <input class="form-control" formControlName="name" id="name" name="name"
                             type="text">
                      <span *ngIf="getError(changeNameForm, 'name')" class="error-message">Vneseno ime je prekratko.</span>
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <div class="col-sm-12 text-sm-center">
                      <button (click)="changeName()" [disabled]="changeNameForm.invalid" class="btn btn-alt-primary"
                              type="submit">
                        <i class="si si-user mr-10"></i> Spremeni ime
                      </button>
                    </div>
                  </div>
                </div>
              </form>

            <form [formGroup]="changeAboutForm" class="js-validation-signin">
                <div class="block-header p-0">
                  <h3 class="block-title">O meni</h3>
                </div>
                <div class="block-content px-0">
                  <div class="form-group row">
                    <div class="col-12">
                      <label for="about">Predstavi se!</label>
                      <input class="form-control" formControlName="about" id="about" name="about"
                             type="text">
                      <span *ngIf="getError(changeAboutForm, 'about')" class="error-message">Vnesen opis je prekratek.</span>
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <div class="col-sm-12 text-sm-center">
                      <button (click)="changeAbout()" [disabled]="changeAboutForm.invalid" class="btn btn-alt-primary"
                              type="submit">
                        <i class="si si-book-open mr-10"></i> Spremeni opis
                      </button>
                    </div>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 d-none d-lg-block">
        <app-sidebar-right></app-sidebar-right>
      </div>
    </div>
  </div>
</main>
