import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable, of, timer} from 'rxjs';
import {Router} from '@angular/router';
import {throwError} from 'rxjs/internal/observable/throwError';
import {catchError, tap} from 'rxjs/operators';
import {AuthenticationService} from '../authentication/authentication.service';
import {SessionService} from '../authentication/session.service';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {makeStateKey, StateKey, TransferState} from '@angular/platform-browser';
import {isPlatformServer} from '@angular/common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  unprotectedRoutes = [
    {url: '/auth/login', postProtected: false},
    {url: '/auth/register', postProtected: false},
    {url: '/auth/activate', postProtected: false},
    {url: '/categories', postProtected: true},
    {url: '/posts', postProtected: true}
    ];
  constructor(private auth: AuthenticationService, private session: SessionService, private router: Router,
              @Inject('location') private location: Location, private modal: NgxSmartModalService,
              private transferState: TransferState, @Inject(PLATFORM_ID) private platformId: any) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers: any = {
      Accept: 'application/json'
    };
    if (!request.url.match(RegExp(/w*\/posts\b/))) {
      headers['Content-Type'] = 'application/json';
    }
    if (this.isProtectedRoute(request)) {
      headers.Authorization = this.auth.getAuthHeaders();
    }
    if (request.url.match(RegExp(/w*\/posts\b/))) {
      request = request.clone({
        setHeaders: headers
      });
    }

    request = request.clone({
      headers: request.headers.set('Authorization', this.auth.getAuthHeaders()).set('Accept', 'application/json')
    });

    const handleRequest = next.handle(request)
      .pipe(
        catchError((error: any, caught: any) => {
            if (error instanceof HttpErrorResponse) {
              const path = this.location.pathname;
              if (error.status === 401 || error.status === 403) {
                this.modal.open('loginModal');
                const stateUrl = path + this.location.search;
                sessionStorage.setItem('loginReturnUrl', stateUrl);

                // On token expired, or other error, destroy session + redirect
                this.session.destroySession();
                return throwError(error.error ? error.error : error);
              } else {
                return throwError(error.error ? error.error : error);
              }
            } else {
              throwError(error.error ? error.error : error);
            }
          }
        ));

    if (request.method !== 'GET') {
      return handleRequest;
    }

    const key: StateKey<string> = makeStateKey<string>(request.url);

    if (isPlatformServer(this.platformId)) {
      return handleRequest.pipe(tap((event) => {
        this.transferState.set(key, (event as HttpResponse<any>).body);
      }));
    } else {
      const storedResponse = this.transferState.get<any>(key, null);
      if (storedResponse) {
        const response = new HttpResponse({body: storedResponse, status: 200});
        this.transferState.remove(key);
        return of(response);
      } else {
        return handleRequest;
      }
    }
  }

  isProtectedRoute(request) {
    return !this.unprotectedRoutes.some(route => request.url.includes(route.url) && (request.method !== 'POST' || (request.method === 'POST' && !route.postProtected)));
  }
}
