import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './dashboard.component';
import {DashboardActivity} from '../core/utils/constants';

const routes: Routes = [
  {path: '', component: DashboardComponent},
  {path: 'hot', component: DashboardComponent},
  {path: 'trending', component: DashboardComponent},
  {path: 'category/:id', component: DashboardComponent, data: {activity: DashboardActivity.Category}},
  {path: 'authentication/activate-account/:id', component: DashboardComponent, data: {activity: DashboardActivity.Activate}},
  {path: 'authentication/reset-password', component: DashboardComponent, data: {activity: DashboardActivity.Reset}},
  {path: 'auth/social/facebook/callback', component: DashboardComponent, data: {activity: DashboardActivity.Facebook}},
  {path: 'auth/social/google/callback', component: DashboardComponent, data: {activity: DashboardActivity.Google}},
  {path: 'search/:query', component: DashboardComponent, data: {activity: DashboardActivity.Search}, runGuardsAndResolvers: 'always'},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule {
}
