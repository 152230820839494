import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Ranking, RankingPeriod} from '../core/models/ranking';

@Component({
  selector: 'app-rankings',
  templateUrl: './rankings.component.html',
  styleUrls: ['./rankings.component.scss']
})
export class RankingsComponent implements OnInit {
  currentWeekRankings: Ranking[];
  currentMonthRankings: Ranking[];
  currentYearRankings: Ranking[];
  pastWeekRankings: Ranking[];
  pastMonthRankings: Ranking[];
  pastYearRankings: Ranking[];
  rankings: Ranking[];
  rankingPeriod = RankingPeriod;
  selectedRankingPeriod: RankingPeriod;
  selectedRankingTime = 'present';

  constructor(private route: ActivatedRoute) {
    this.assignRankings(this.route.snapshot.data.rankings);
  }

  ngOnInit() {
  }

  assignRankings(rankings: Ranking[][]) {
    this.currentWeekRankings = rankings[0];
    this.currentMonthRankings = rankings[1];
    this.currentYearRankings = rankings[2];
    this.pastWeekRankings = rankings[3];
    this.pastMonthRankings = rankings[4];
    this.pastYearRankings = rankings[5];
    this.selectRankingsPeriod(RankingPeriod.ThisWeek);
  }
  selectRankingsPeriod(period: RankingPeriod) {
    this.selectedRankingPeriod = period;
    if (period === RankingPeriod.ThisWeek) {
      this.rankings = this.selectedRankingTime === 'present' ? this.currentWeekRankings : this.pastWeekRankings;
    } else if (period === RankingPeriod.ThisMonth) {
      this.rankings = this.selectedRankingTime === 'present' ? this.currentMonthRankings : this.pastMonthRankings;
    } else if (period === RankingPeriod.ThisYear) {
      this.rankings = this.selectedRankingTime === 'present' ? this.currentYearRankings : this.pastYearRankings;
    }
  }
  selectRankingTime(periodTimeType: string) {
    this.selectedRankingTime = periodTimeType;
    this.selectRankingsPeriod(this.selectedRankingPeriod);
  }
}
