import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {PostsService} from '../core/content/posts.service';
import {Post} from '../core/models/post';

@Injectable({
  providedIn: 'root'
})
export class SinglePostResolver implements Resolve<Observable<Post>> {
  constructor(private postsService: PostsService) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    return this.postsService.getSinglePost(route.paramMap.get('id'));
  }
}
