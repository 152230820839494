import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Category} from '../../core/models/post';
import {BehaviorSubject} from 'rxjs';
import {popularCategories} from 'src/app/core/utils/constants';
import {CategoriesService} from '../../core/content/categories.service';
import {skip} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  categories: Category[];
  popularCategories = popularCategories;
  selectedPopularCategory;
  selectedCategoryIndex: BehaviorSubject<number> = new BehaviorSubject(0);
  @Output() categorySelected = new EventEmitter<string>();
  @Input() isCategoryRedirect: boolean;

  constructor(private categoriesService: CategoriesService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    const url = this.route.snapshot.url;
    this.categoriesService.categories.pipe().subscribe(categories => {
      if (categories) {
        this.categories = categories.filter(cat => cat.isVisible);
        if (url[0] && url[0].path === 'category') {
          this.selectedCategoryIndex.next(this.categories.findIndex((el: Category) => el.slug === url[1].path));
        }
      }
    });
    this.selectedCategoryIndex.asObservable()
      .pipe(
        skip(1)
      ).subscribe(val => {
      if (this.selectedPopularCategory == null) {
        this.categorySelected.emit(this.categories[val].slug);
        this.categoriesService.selectedCategorySlug.next(this.categories[val].slug);
      } else {
        this.categorySelected.emit(this.popularCategories[val].slug);
        this.categoriesService.selectedCategorySlug.next(this.popularCategories[val].slug);
      }
    });
    if (url[0]) {
      if (url[0].path === 'trending') {
        this.selectedPopularCategory = 1;
        this.selectedCategoryIndex.next(1);
      } else if (url[0].path === 'hot') {
        this.selectedPopularCategory = 0;
        this.selectedCategoryIndex.next(0);
      }
    }
  }

  selectCategory(index, isPopular = false) {
    if (this.isCategoryRedirect) {
      if (isPopular) {
        this.router.navigate(['/', `${this.popularCategories[index].slug}`]);
      } else {
        this.router.navigate(['/', 'category', `${this.categories[index].slug}`]);
      }
    } else {
      if (isPopular) {
        this.selectedPopularCategory = index;
      } else {
        this.selectedPopularCategory = null;
      }
      this.selectedCategoryIndex.next(index);
    }
  }
}
