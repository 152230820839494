import {AfterViewInit, Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {Post, PostComment} from '../../core/models/post';
import {PostType, ReactType} from '../../core/utils/constants';
import {PostsService} from '../../core/content/posts.service';
import {ToastrService} from 'ngx-toastr';
import {FormControl, Validators} from '@angular/forms';
import {noop} from 'rxjs';
import {Router} from '@angular/router';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {ReportsService} from '../../core/content/reports.service';
import {ReportableType, ReportedResource} from '../../core/models/report';
import {SessionService} from '../../core/authentication/session.service';
import {tap} from 'rxjs/operators';
import {isPlatformBrowser, Location} from "@angular/common";

declare var FB;

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit, AfterViewInit {
  @Input() post: Post;
  @Input() isSinglePostView = false;
  @Input() showAd = false;
  reportableType = ReportableType;
  postType = PostType;
  reactType = ReactType;
  commentControl: FormControl;
  isBrowser: boolean;

  constructor(private postsService: PostsService,
              private toastr: ToastrService,
              private router: Router,
              private modal: NgxSmartModalService,
              private reportsService: ReportsService,
              public session: SessionService,
              @Inject(PLATFORM_ID) platformId: Object) {
                this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    if (this.isSinglePostView) {
      this.commentControl = new FormControl(null, [Validators.required, Validators.minLength(5)]);
    }
  }

  ngAfterViewInit() {
    this.createObserver();
    if (this.post.postType === PostType.Facebook) {
      if (this.isBrowser) {
        FB.XFBML.parse(document.getElementById('fb-video' + this.post.slug));
      }
    }
  }

  createObserver() {
    if (!('IntersectionObserver' in window)) return;
    const options = {
      rootMargin: '0px',
      threshold: 1.0
    };

    const observer = new IntersectionObserver((entries, observer) => {
      if (entries[0].isIntersecting) {
        this.postsService.incrementViews(this.post.slug)
          .pipe(
            tap(() => observer.disconnect())
          ).subscribe();
      }
    }, options);
    const target = document.querySelector(`#title-${this.post.slug}`);
    observer.observe(target);
  }

  routeTo() {
    if (!this.isSinglePostView) {
      this.router.navigate(['/post', this.post.slug]);
    }
  }

  share() {
    if (this.isUserLoggedIn()) {
      const url = `${window.location.origin}/post/${this.post.slug}`;
      FB.ui({
        method: 'share',
        href: url
      }, (response) => console.log(response));
    }
  }

  react(type: ReactType) {
    if (this.isUserLoggedIn()) {
      const isDuplicate = this.isDuplicateReaction(type);
      this.post.react(type, isDuplicate);
      this.postsService.reactToPost(this.post.slug, type)
        .subscribe(
          (_) => noop(),
          (_) => this.post.react(type, !isDuplicate)
        );
    }
  }

  isDuplicateReaction(type) {
    return (this.post.isDislikedByUser && type === ReactType.Dislike) || (this.post.isLikedByUser && type === ReactType.Like);
  }

  postComment() {
    if (this.isUserLoggedIn()) {
      this.postsService.postComment(this.post.id, this.commentControl.value).subscribe((comment: PostComment) => {
        this.post.comments.push(comment);
        this.commentControl.reset();
        this.toastr.success('Komentar oddan');
      });
    }
  }

  copyLink() {
    const url = `${window.location.origin}/post/${this.post.slug}`;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success('Url do te objave shranjen v odložišče');
  }

  openReportModal(reportableId, reportableType) {
    if (this.session.isLoggedIn) {
      this.reportsService.currentlyReportedResource.next(new ReportedResource({reportableId, reportableType}));
      this.modal.open('reportModal');
    }
  }

  isUserLoggedIn() {
    if (this.session.isLoggedIn) {
      return true;
    } else {
      this.modal.open('loginModal');
      return false;
    }
  }
}
