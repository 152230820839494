<div class="block block-rounded highscore">
  <div class="block-header">
    <div class="highscore-icon">
      <img alt="trophy icon" src="assets/img/trophy.png">
    </div>
    <h3 class="block-title font-w700">Lestvica uporabnikov</h3>
    <h6 class="font-w700 mb-2">Mesečne denarne nagrade. <a [routerLink]="['/page', 'lestvica-navodila']">Več info</a></h6>
  </div>
  <div class="block-content">
    <ul *ngIf="rankings && rankings.length" class="nav-users">
      <li *ngFor="let ranking of rankings; let i = index;" [routerLink]="['/profile', ranking.username]" tabindex="-1">
        <div class="highscore-placement">{{i + 1}}</div>
        <div class="highscore-user">{{ranking.name}}</div>
        <div class="highscore-score">{{ranking.points | number}}</div>
      </li>
    </ul>
    <div class="text-center">
      <app-spinner *ngIf="isLoading"></app-spinner>
    </div>
    <p *ngIf="!isLoading && (!rankings || !rankings.length)" class="text-center pb-0">Lestvica je zaenkrat še
      prazna.</p>
    <button [routerLink]="['/', 'rankings']" class="btn btn-primary btn-sm" type="button">
      <i class="si si-eye"></i>
      Prikaži vse...
    </button>
  </div>
</div>
