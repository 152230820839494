import {Component, Input, OnInit} from '@angular/core';
import {Activity, ActivityType} from '../../core/models/activity';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {
  @Input() activity: Activity;
  activityTexts = new Map([
    [ActivityType.AddedComment, 'dodal komentar'],
    [ActivityType.DeletedComment, 'izbrisal komentar'],
    [ActivityType.AddedPost, 'dodal objavo'],
    [ActivityType.DeletedPost, 'izbrisal objavo'],
    [ActivityType.AddedReaction, 'reagiral'],
    [ActivityType.DeletedReaction, 'odstranil reakcijo'],
  ]);
  constructor() {
  }

  ngOnInit() {
  }

  get taglineText() {
    return this.activityTexts.get(this.activity.type);
  }

  get iconClasses() {
    return this.activity.points > 0 ? 'fa mr-5 green-text fa-arrow-up' : 'fa mr-5 red-text fa-arrow-down';
  }
}
