import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {UserProfile} from '../models/user';
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  authToken: string | null = null;
  username: string;
  userProfile: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(null);
  isBrowser;

  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  get isLoggedIn() {
    return !!this.getAuthToken();
  }

  setAuthToken(token: string) {
    this.authToken = token;
    if (this.isBrowser) {
      localStorage.setItem(Session.ACCESS_TOKEN, btoa(encodeURIComponent(token)));
    }
  }

  setUsername(username: string) {
    this.username = username;
  }

  setUser(user: UserProfile) {
    if (this.isLoggedIn) {
      this.userProfile.next(user);
      if (this.isBrowser) {
        localStorage.setItem(Session.USER, btoa(encodeURIComponent(JSON.stringify(user))));
      }
    }
  }

  getUsername() {
    return this.username;
  }

  getAuthToken() {
    if (this.authToken) {
      return this.authToken;
    }
    if (this.isBrowser) {
      this.authToken = localStorage.getItem(Session.ACCESS_TOKEN) ?
        decodeURIComponent(atob(localStorage.getItem(Session.ACCESS_TOKEN))) : null;
    }
    return this.authToken;
  }

  getUser() {
    if (this.userProfile.getValue()) {
      return this.userProfile.getValue();
    }

    if (this.isBrowser) {
      const savedUserProfile = localStorage.getItem(Session.USER) ?
        decodeURIComponent(atob(localStorage.getItem(Session.USER))) : null;
      if (savedUserProfile) {
        this.userProfile.next(new UserProfile(JSON.parse(savedUserProfile)));
      }

    }

    return this.userProfile.getValue();
  }

  destroySession() {
    this.authToken = null;
    this.userProfile.next(null);
    if (this.isBrowser) {
      localStorage.removeItem(Session.ACCESS_TOKEN);
      localStorage.removeItem(Session.USER);
    }
  }
}

export enum Session {
  ACCESS_TOKEN = 'access_token',
  USER = 'izklop_user',
}
