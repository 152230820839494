import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {RankingsService} from '../core/content/rankings.service';
import {Ranking} from '../core/models/ranking';

@Injectable({
  providedIn: 'root'
})
export class RankingsResolver implements Resolve<Observable<[Ranking[], Ranking[], Ranking[], Ranking[], Ranking[], Ranking[]]>> {
  constructor(private rankingsService: RankingsService) {
  }

  resolve(routeSnapshot: ActivatedRouteSnapshot) {
    return this.rankingsService.getAllRankings();
  }
}
