import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FileUploadComponent} from './file-upload/file-upload.component';
import {HighscoreComponent} from './highscore/highscore.component';
import {PostComponent} from './post/post.component';
import {CategoriesComponent} from './categories/categories.component';
import {CoreModule} from '../core/core.module';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {ActivityComponent} from './activity/activity.component';
import {CommentComponent} from './comment/comment.component';
import {SpinnerComponent} from './spinner/spinner.component';
import { AdComponent } from './ad/ad.component';

@NgModule({
  declarations: [FileUploadComponent, HighscoreComponent, PostComponent, CategoriesComponent, ActivityComponent, CommentComponent,
    SpinnerComponent, AdComponent],
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
    ReactiveFormsModule
  ],
  exports: [FileUploadComponent, HighscoreComponent, PostComponent, CategoriesComponent, CommentComponent, ActivityComponent,
    SpinnerComponent, AdComponent]
})
export class SharedModule {
}
