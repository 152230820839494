<div class="block block-rounded text-center font-w600">
  <div class="block-content block-content-full bg-gd-sea pb-0">
    <img [src]="user?.avatar ? user.avatar : 'assets/img/user.png'" alt="users_avatar"
         class="img-avatar img-avatar-thumb" routerLink="/profile">
  </div>
  <div class="block-content block-content-full">
    <div *ngIf="user && session.isLoggedIn;else noLogin">
      <div class="py-2">
        <button (click)="createPost()" class="btn btn-success w-100" type="button">
          <i class="fa fa-pencil-square-o mr-2"></i>
          <span class="d-none d-sm-inline">Dodaj objavo</span>
        </button>
      </div>
      <div class="pt-2 pb-4 border-b mb-3">
        <button (click)="doAuth(authTypes.Logout)" class="btn btn-primary w-100">
          <i class="si si-login mr-2"></i> Odjava
        </button>
      </div>
      <div class="mb-2 mt-2">
        {{user.name}}
      </div>
      <div class="row gutters-tiny">
        <div class="col-12">
          <div class="h2 red-text font-weight-bold mb-2">#{{user.position}}</div>
        </div>
        <div class="col-12">
          <div class="font-size-md text-muted mb-0">{{user.points}} točk aktivnosti</div>
        </div>
      </div>
    </div>
    <ng-template #noLogin>
      <div class="mb-2">
        <h5 class="d-block mb-2">
          Živijo!
        </h5>
        <div class="py-2">
          <button (click)="createPost()" class="btn btn-success w-100" type="button">
            <i class="fa fa-pencil-square-o mr-2"></i>
            <span class="d-none d-sm-inline">Dodaj objavo</span>
          </button>
        </div>
        <div class="pt-2 pb-4 border-b mb-3">
          <button (click)="doAuth(authTypes.Login)" class="btn btn-primary w-100">
            <i class="si si-login mr-2"></i> Prijava
          </button>
        </div>
        <div class="d-block">
          Še nimate računa? Ustvari račun!
        </div>
      </div>
      <div class="row gutters-tiny">
        <div class="col-4">
          <button (click)="doAuth(authTypes.Register)" class="btn btn-circle btn-login-option" type="button"><img
            alt="user enter button image" src="assets/img/enter.png"></button>
          <div class="font-size-xs text-muted">Uporabnik</div>
        </div>
        <div class="col-4">
          <button (click)="doAuth(authTypes.Facebook)" class="btn btn-circle btn-login-option" type="button"><img
            alt="user enter with facebook button image" src="assets/img/facebook.png"></button>
          <div class="font-size-xs text-muted">Facebook</div>
        </div>
        <div class="col-4">
          <button (click)="doAuth(authTypes.Google)" class="btn btn-circle btn-login-option" type="button"><img
            alt="user enter with google button image" src="assets/img/google.png"></button>
          <div class="font-size-xs text-muted">Google</div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
