import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SessionService} from '../core/authentication/session.service';
import {AuthenticationService} from '../core/authentication/authentication.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSmartModalService} from 'ngx-smart-modal';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotForm: FormGroup;

  constructor(private session: SessionService,
              private auth: AuthenticationService,
              private fb: FormBuilder,
              private toastr: ToastrService,
              private modal: NgxSmartModalService) {
  }

  ngOnInit() {
    this.forgotForm = this.fb.group(
      {
        email: [{value: null, disabled: false}, Validators.compose([
          Validators.email,
          Validators.required])
        ]
      });
  }
  requestPasswordReset() {
    this.auth.requestPasswordReset(this.forgotForm.getRawValue().email).subscribe(
      (_) => {
        this.toastr.success('Zahtevek za spremembo gesla uspešno oddan! Povezava za ponastavitev gesla je bila poslana na vnešeni email.');
        this.modal.getModal('forgotPasswordModal').close();
      }
    );
  }
  openLogin() {
    this.modal.getModal('loginModal').open();
    this.modal.getModal('forgotPasswordModal').close();
  }
  getError(controlName: string) {
    const fc = this.forgotForm.get(controlName);
    return fc.touched && fc.invalid;
  }
}
