<div class="row">
  <div class="col-lg-3 d-none d-lg-block">
    <app-sidebar-left [isHighScoreVisible]="false"></app-sidebar-left>
  </div>
  <div class="col-lg-6">
    <div class="block block-rounded">
      <div class="block block-rounded highscore">
        <div class="block-header">
          <div class="highscore-icon">
            <img alt="trophy icon" src="assets/img/trophy.png">
          </div>
          <h3 class="block-title font-w700">Lestvica uporabnikov</h3>
          <h6 class="font-w700 mb-2">Mesečne denarne nagrade. <a [routerLink]="['/page', 'lestvica-navodila']">Več info</a></h6>
        </div>
        <div class="block-content text-center">
          <ul class="nav-users text-left">
            <ng-container *ngIf="rankings.length">
              <li *ngFor="let ranking of rankings; let i = index;" [routerLink]="['/profile', ranking.username]"
                  tabindex="-1">
                <div class="highscore-placement">{{i + 1}}</div>
                <div class="highscore-user">{{ranking.name}}</div>
                <div class="highscore-score">{{ranking.points | number}}</div>
              </li>
            </ng-container>
            <ng-container *ngIf="!rankings.length">
              <p class="text-muted text-center">Zaenkrat še ni zadetkov</p>
            </ng-container>
          </ul>
          <div class="d-flex justify-content-between">
            <div aria-label="btnGroupRankings" class="btn-group" role="group">
              <button (click)="selectRankingsPeriod(rankingPeriod.ThisWeek)"
                      [ngClass]="{'active': selectedRankingPeriod === rankingPeriod.ThisWeek}"
                      class="btn btn-outline-primary"
                      type="button">
                Teden
              </button>
              <button (click)="selectRankingsPeriod(rankingPeriod.ThisMonth)"
                      [ngClass]="{'active': selectedRankingPeriod === rankingPeriod.ThisMonth}"
                      class="btn btn-outline-primary"
                      type="button">
                Mesec
              </button>
              <button (click)="selectRankingsPeriod(rankingPeriod.ThisYear)"
                      [ngClass]="{'active': selectedRankingPeriod === rankingPeriod.ThisYear}"
                      class="btn btn-outline-primary"
                      type="button">
                Leto
              </button>
            </div>

            <div aria-label="btnGroupRankings" class="btn-group" role="group">
              <button (click)="selectRankingTime('present')"
                      [ngClass]="{'active': selectedRankingTime === 'present'}"
                      class="btn btn-outline-primary"
                      type="button">
                Trenutni
              </button>
              <button (click)="selectRankingTime('past')"
                      [ngClass]="{'active': selectedRankingTime === 'past'}"
                      class="btn btn-outline-primary"
                      type="button">
                Pretekli
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3 d-none d-lg-block">
    <app-sidebar-right></app-sidebar-right>
  </div>
</div>
