import {Category} from '../models/post';
import {HttpEvent, HttpEventType, HttpResponse} from '@angular/common/http';
import {filter, map, tap} from 'rxjs/operators';
import {pipe} from 'rxjs';

export enum PostType {
  Image = 1,
  Youtube = 2,
  Facebook = 3,
  Streamable = 4,
  Ad = 5
}

export enum PostTypeBasic {
  Image,
  Video
}

export enum ReactType {
  Like = 'like',
  Dislike = 'dislike',
  Impress = 'impress'
}

export const videoPattern = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?|^(?:(?:https?:)?\/\/)?(?:www\.)?facebook\.com\/[a-zA-Z0-9\.]+\/videos\/(?:[a-zA-Z0-9\.]+\/)?([0-9]+)|^(?:(?:https?:)?\/\/)?(?:www\.)?streamable\.com\/[a-zA-Z0-9\.]?/;
export const videoYtPattern = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/;
export const videoFbPattern = /^(?:(?:https?:)?\/\/)?(?:www\.)?facebook\.com\/[a-zA-Z0-9\.]+\/videos\/(?:[a-zA-Z0-9\.]+\/)?([0-9]+)/;
export const videoStreamablePattern = /^(?:(?:https?:)?\/\/)?(?:www\.)?streamable\.com\/[a-zA-Z0-9\.]?/;
export const popularCategories: {label: string, icon: string, slug: string}[] = [{label: 'VROČE', icon: 'si-fire', slug: 'hot'}, {label: 'POPULARNO', icon: 'si-chart', slug: 'trending'}];
export function toFormData<T>( formValue: T ) {
  const formData = new FormData();

  for (const key of Object.keys(formValue)) {
    const value = formValue[key];
    formData.append(key, value);
  }

  return formData;
}
export function uploadProgress<T>( cb: ( progress: number ) => void ) {
  return tap(( event: HttpEvent<T> ) => {
    if ( event.type === HttpEventType.UploadProgress ) {
      cb(Math.round((100 * event.loaded) / event.total));
    }
  });
}

export function toResponseBody<T>() {
  return pipe(
    filter(( event: HttpEvent<T> ) => event.type === HttpEventType.Response),
    map(( res: HttpResponse<T> ) => res.body)
  );
}

export const getDiffDateText = (datePast: string, dateFuture = new Date()) => {
  const seconds = dateFuture.getTime() / 1000 - new Date(datePast).getTime() / 1000;
  if (seconds < 60) {
    return `${seconds.toFixed(0)} s`;
  } else if (seconds < 3600) {
    return `${(seconds / 60).toFixed(0)} min`;
  } else if (seconds < 86400) {
    return `${(seconds / 3600).toFixed(0)} ur`;
  } else if (seconds < 2592000) {
    return `${(seconds / 86400).toFixed(0)} dni`;
  } else if (seconds < 31536000) {
    return `${(seconds / 2592000).toFixed(0)} mesecev`;
  } else {
    return `${(seconds / 31536000).toFixed(0)} let`;
  }
};

export enum DashboardActivity {
  Category,
  Activate,
  Reset,
  Facebook,
  Google,
  Search
}
