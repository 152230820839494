import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PostsService} from '../core/content/posts.service';
import {CategoriesService} from '../core/content/categories.service';
import {Category} from '../core/models/post';
import {requiredFileType} from '../core/utils/custom-validators';
import {ToastrService} from 'ngx-toastr';
import {PostType, PostTypeBasic, videoFbPattern, videoPattern, videoStreamablePattern, videoYtPattern} from '../core/utils/constants';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {Router} from '@angular/router';

@Component({
  selector: 'app-add-post',
  templateUrl: './add-post.component.html',
  styleUrls: ['./add-post.component.scss']
})
export class AddPostComponent implements OnInit {
  postForm: FormGroup;
  categories: Category[];
  postTypeBasic = PostTypeBasic;
  type = PostTypeBasic.Video;

  constructor(private fb: FormBuilder,
              private postsService: PostsService,
              private categoriesService: CategoriesService,
              private toastr: ToastrService,
              private modal: NgxSmartModalService,
              private router: Router) {
  }

  ngOnInit() {
    this.categoriesService.getCategories().subscribe(categories => this.categories = categories.filter(cat => !cat.isSpecial));
    this.postForm = this.fb.group(
      {
        title: [{value: null, disabled: false}, [Validators.required]],
        description: [{value: null, disabled: false}, [Validators.required]],
        categoryId: [{value: null, disabled: false}, [Validators.required]],
        mediaUrl: [{value: null, disabled: false}, [Validators.required, Validators.pattern(videoPattern)]],
        mediaImage: [{value: null, disabled: false}, null],
      });
  }

  hasError(field: string, error: string) {
    const control = this.postForm.get(field);
    return control.dirty && control.hasError(error);
  }

  createPost() {
    const postData = {...this.postForm.getRawValue(), postType: this.getPostType()};
    if (postData.postType === PostType.Streamable) {
      if (!postData.mediaUrl.includes('/s/')) {
        postData.mediaUrl = 'https://streamable.com/s/' + postData.mediaUrl.split('.com/')[1];
      }
    }
    this.postsService.createPost(postData)
      .subscribe(
        (post) => {
          this.router.navigate(['/', 'post', post.slug]);
          this.toastr.success('Objavljeno!');
          this.postForm.reset();
          this.modal.getModal('addPostModal').close();
        },
        () => this.toastr.error('Objava ni uspela. Prosimo, poizkusite kasneje.')
      );
  }

  getPostType() {
    if (this.type === PostTypeBasic.Image) {
      return PostType.Image;
    } else if (this.postForm.get('mediaUrl').value.match(RegExp(videoYtPattern))) {
      return PostType.Youtube;
    } else if (this.postForm.get('mediaUrl').value.match(RegExp(videoFbPattern))) {
      return PostType.Facebook;
    } else if (this.postForm.get('mediaUrl').value.match(RegExp(videoStreamablePattern))) {
      return PostType.Streamable;
    }
  }

  setType(type: PostTypeBasic) {
    const url = this.postForm.get('mediaUrl');
    const img = this.postForm.get('mediaImage');
    if (this.type !== type) {
      this.type = type;
      if (type === PostTypeBasic.Video) {
        url.setValidators([Validators.required, Validators.pattern(videoPattern)]);
        img.clearValidators();
      } else {
        img.setValidators([Validators.required, requiredFileType(['png', 'jpg', 'jpeg', 'gif'])]);
        url.clearValidators();
      }
      url.updateValueAndValidity();
      img.updateValueAndValidity();
      this.postForm.updateValueAndValidity();
    }
  }

  getBtnClass(type: PostTypeBasic) {
    return `btn ${this.type === type ? 'btn-primary' : 'btn-outline-primary'}`;
  }

  isSectionShown(type: PostTypeBasic) {
    return this.type === type;
  }
}
