import {Component, OnDestroy, OnInit} from '@angular/core';
import {Pagination, Post, PostComment} from '../core/models/post';
import {AuthenticationService} from '../core/authentication/authentication.service';
import {ToastrService} from 'ngx-toastr';
import {PostsService} from '../core/content/posts.service';
import {CategoriesService} from '../core/content/categories.service';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';
import {ProfileService} from '../core/content/profile.service';
import {ChangePassword, UserProfile} from '../core/models/user';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SessionService} from '../core/authentication/session.service';
import {Activity} from '../core/models/activity';
import {ReportableType, ReportedResource} from '../core/models/report';
import {ReportsService} from '../core/content/reports.service';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {Subscription} from 'rxjs';
import {matchPassword} from '../core/utils/custom-validators';
import {distinctUntilChanged, skip} from "rxjs/operators";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  posts: Post[];
  comments: PostComment[];
  activities: Activity[];
  user: UserProfile;
  pagination: Pagination;
  tabs = ProfileTab;
  chosenTab = ProfileTab.Posts;
  imageControl = new FormControl();
  isOwnProfile = false;
  isChangeImage = false;
  isImagePreview = false;
  previewImageSrc = '';
  reportableType = ReportableType;
  navigationSubscription: Subscription;
  changePasswordForm: FormGroup;
  changeNameForm: FormGroup;
  changeAboutForm: FormGroup;

  constructor(private authService: AuthenticationService,
              private toastr: ToastrService,
              private postsService: PostsService,
              private categoriesService: CategoriesService,
              private profileService: ProfileService,
              private route: ActivatedRoute,
              private router: Router,
              public session: SessionService,
              private reportsService: ReportsService,
              private modal: NgxSmartModalService,
              private fb: FormBuilder) {
    this.assignProfileData(this.route.snapshot.data.profileData);
  }

  ngOnInit() {
    this.isOwnProfile = !this.route.snapshot.params.id;
    if (this.isOwnProfile) {
      this.initForms();
    }
    this.navigationSubscription = this.router.events.subscribe((e: RouterEvent) => {
      if (e instanceof NavigationEnd) {
        this.assignProfileData(this.route.snapshot.data.profileData);
      }
    });
    this.handleImagePreview();
    this.listenForOwnProfileChange();
    this.getPositionForUser();
  }

  getPositionForUser() {
    this.profileService.getUserPosition(this.user.username).subscribe((position) => this.user.position = position);
  }

  initForms() {
    this.changePasswordForm = this.fb.group(
      {
        oldPassword: [{value: null, disabled: false}, Validators.compose([Validators.required, Validators.minLength(8)])],
        password: [{value: null, disabled: false}, Validators.compose([Validators.required, Validators.minLength(8)])],
        passwordConfirmation: [{value: null, disabled: false}, Validators.compose([Validators.required, Validators.minLength(8)])],
      }, {
        validators: [matchPassword]
      });
    this.changeNameForm = this.fb.group({
      name: [{value: null, disabled: false}, Validators.compose([Validators.required, Validators.minLength(3)])],
    });
    this.changeAboutForm = this.fb.group({
      about: [{value: null, disabled: false}, Validators.compose([Validators.required, Validators.minLength(3)])],
    });
  }

  assignProfileData([user, posts, comments, activities]: [UserProfile, Post[], PostComment[], Activity[]]) {
    this.user = user;
    this.posts = posts;
    this.posts.forEach(post => post.setUser(this.user));
    this.comments = comments;
    this.activities = activities;
    this.activities.forEach(activity => activity.name = this.user.name);
  }

  fetchUserInfo(userId) {
    this.profileService.getWholeUserProfile(userId).subscribe(profileData => {
      this.assignProfileData(profileData);
    });
  }

  listenForOwnProfileChange() {
    this.session.userProfile
      .pipe(
        distinctUntilChanged((user1, user2) => user1.username === user2.username),
        skip(1)
      )
      .subscribe(user => {
      if (user && user.username && !this.route.snapshot.params.id) {
        this.fetchUserInfo(user.username);
      }
    });
  }

  handleImagePreview() {
    this.imageControl.valueChanges.subscribe(value => {
      const reader = new FileReader();
      reader.addEventListener('load', (event: any) => {
        this.previewImageSrc = event.target.result;
        this.isImagePreview = true;
      });
      reader.readAsDataURL(value);
    });
  }

  handleImageSelect(isConfirm) {
    if (isConfirm) {
      this.profileService.changeUserAvatar(this.imageControl.value)
        .subscribe((user: UserProfile) => {
            this.user.avatar = user.avatar;
            this.toastr.success('Uspešno ste spremenili vašo profilno sliko');
            this.isChangeImage = !this.isChangeImage;
          },
          () => this.toastr.error('Nalaganje ni uspelo. Poskusite z drugim avatarjem'));
    } else {
      if (this.isImagePreview) {
        this.isImagePreview = false;
        this.previewImageSrc = '';
      } else {
        this.isChangeImage = false;
      }
    }
  }

  chooseTab(tab: ProfileTab) {
    this.chosenTab = tab;
  }

  reportComment(reportableId, reportableType) {
    this.reportsService.currentlyReportedResource.next(new ReportedResource({reportableId, reportableType}));
    this.modal.open('reportModal');
  }

  getError(formGroup: FormGroup, controlName: string) {
    const fc = formGroup.get(controlName);
    return fc.touched && fc.invalid;
  }

  changePassword() {
    this.profileService.changeUserPassword(new ChangePassword(this.changePasswordForm.getRawValue()))
      .subscribe(() => {
          this.toastr.success('Geslo uspešno spremenjeno');
          this.changePasswordForm.reset();
        },
        () => this.toastr.error('Sprememba gesla neuspešna. Prosimo, poizkusite kasneje.'));
  }

  changeName() {
    const name = this.changeNameForm.getRawValue().name;
    this.profileService.changeUserName(name)
      .subscribe(() => {
          this.user.name = name;
          this.posts.forEach(post => post.user.name = name);
          this.comments.forEach(comment => comment.name = name);
          this.activities.forEach(activity => activity.name = name);
          this.toastr.success('Ime uspešno spremenjeno');
          this.changeNameForm.reset();
        },
        () => this.toastr.error('Sprememba imena neuspešna. Prosimo, poizkusite kasneje.'));
  }

  changeAbout() {
    const about = this.changeAboutForm.getRawValue().about;
    this.profileService.changeUserAbout(about)
      .subscribe(() => {
          this.user.about = about;
          this.toastr.success('Opis uspešno spremenjeno');
          this.changeAboutForm.reset();
        },
        () => this.toastr.error('Sprememba opisa neuspešna. Prosimo, poizkusite kasneje.'));
  }

  getPasswordError() {
    const isPasswordError = this.changePasswordForm.hasError('passwordsDontMatch');
    return this.changePasswordForm.get('passwordConfirmation').touched && this.changePasswordForm.get('password').touched && isPasswordError;
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}

export enum ProfileTab {
  Posts,
  Comments,
  Activity,
  About,
  Edit
}
