import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {PagesService} from '../core/content/pages.service';
import {Page} from '../core/models/page';

@Injectable({
  providedIn: 'root'
})
export class StaticPageResolver implements Resolve<Observable<Page>> {
  constructor(private pagesService: PagesService) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    return this.pagesService.getPage(route.paramMap.get('id'));
  }
}
