import {Component, OnInit} from '@angular/core';
import {RankingsService} from '../../core/content/rankings.service';
import {Ranking, RankingPeriod} from '../../core/models/ranking';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-highscore',
  templateUrl: './highscore.component.html',
  styleUrls: ['./highscore.component.scss']
})
export class HighscoreComponent implements OnInit {
  rankings: Ranking[];
  isLoading: boolean;

  constructor(private rankingsService: RankingsService) {
    this.isLoading = true;
    this.rankingsService.getRankings(RankingPeriod.ThisWeek)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe((rankings) => this.rankings = rankings);
  }

  ngOnInit() {
  }

}
