import {AbstractControl, FormControl} from '@angular/forms';

export function requiredFileType( types: string[] ) {
  return function (control: FormControl) {
    const file = control.value;
    if (file) {
      const extension = file.name.split('.').slice(-1)[0] .toLowerCase();
      if (!types.some(type => type.toLowerCase() === extension.toLowerCase())) {
        return {requiredFileType: true};
      }
      return null;
    }
    return null;
  };
}

export const matchPassword = (control: AbstractControl) => {
  const password = control.get('password').value;
  const passwordConfirmation = control.get('passwordConfirmation').value;
  if (password && passwordConfirmation && password !== passwordConfirmation) {
    return {passwordsDontMatch: true};
  } else {
    return null;
  }
};
