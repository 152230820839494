import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PostComment} from '../../core/models/post';
import {SessionService} from '../../core/authentication/session.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {
  @Input() comment: PostComment;
  @Output() report = new EventEmitter<number>();

  constructor(public session: SessionService) {
  }

  ngOnInit() {
  }

  reportComment() {
    this.report.emit(this.comment.id);
  }

}
