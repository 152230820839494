<ngx-smart-modal #resetModal [closable]="false" identifier="resetModal">
  <form [formGroup]="resetForm" class="js-validation-signin">
    <div class="block block-themed block-rounded block-shadow mb-0">
      <div class="block-header bg-gd-dusk">
        <h3 class="block-title">Ustvari novo geslo</h3>
        <div class="block-options">
          <button (click)="resetModal.close()" class="btn-block-option" type="button">
            <i class="si si-close"></i>
          </button>
        </div>
      </div>
      <div class="block-content">
        <div class="form-group row">
          <div class="col-12">
            <label for="login-email">Email</label>
            <input class="form-control" formControlName="email" id="login-email" name="login-email" type="text">
            <span class="error-message" *ngIf="getError('email')">Vnesite veljaven email.</span>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12">
            <label for="reset-password">Geslo</label>
            <input class="form-control" formControlName="password" id="reset-password" name="reset-password"
                   type="password">
            <span class="error-message" *ngIf="getError('password')">Vnesite veljavno geslo.</span>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12">
            <label for="reset-password-confirm">Ponovite geslo</label>
            <input class="form-control" formControlName="passwordConfirmation" id="reset-password-confirm" name="reset-password-confirm"
                   type="password">
            <span class="error-message" *ngIf="getPasswordError()">Gesli se ne ujemata.</span>
          </div>
        </div>
        <div class="form-group row mb-3">
          <div class="col-sm-12 text-sm-center">
            <button class="btn btn-alt-primary" type="submit" (click)="resetPassword()" [disabled]="resetForm.invalid">
              <i class="si si-login mr-10"></i> Spremeni geslo
            </button>
          </div>
        </div>
      </div>
      <div class="block-content bg-body-light">
        <div class="form-group text-center">
          <a class="link-effect text-muted mr-10 mb-5 d-inline-block" (click)="openRegister()">
            <i class="fa fa-plus mr-5"></i> Nov uporabnik
          </a>
          <a class="link-effect text-muted mr-10 mb-5 d-inline-block" (click)="openForgot()">
            <i class="fa fa-warning mr-5"></i> Pozabljeno geslo
          </a>
        </div>
      </div>
    </div>
  </form>
</ngx-smart-modal>
