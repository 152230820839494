import {Component, OnInit} from '@angular/core';
import {SessionService} from '../../core/authentication/session.service';
import {AuthTypes, UserProfile} from '../../core/models/user';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {AuthenticationService} from '../../core/authentication/authentication.service';
import {Router} from '@angular/router';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-profile-preview',
  templateUrl: './profile-preview.component.html',
  styleUrls: ['./profile-preview.component.scss']
})
export class ProfilePreviewComponent implements OnInit {
  user: UserProfile;
  authTypes = AuthTypes;
  constructor(public session: SessionService,
              private modal: NgxSmartModalService,
              private auth: AuthenticationService,
              private router: Router,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.session.userProfile.subscribe(user => this.user = user);
  }
  doAuth(authType: AuthTypes) {
    if (authType === AuthTypes.Logout) {
      this.session.destroySession();
      this.toastr.success('Uspešno ste se odjavili.');
      this.router.navigate(['/']);
    } else if (authType === AuthTypes.Login) {
      this.modal.getModal('loginModal').open();
    } else if (authType === AuthTypes.Register) {
      this.modal.getModal('registerModal').open();
    } else if (authType === AuthTypes.Facebook) {
      this.auth.getFBSignInUrl().subscribe((res: {data: string}) => window.location.href = res.data);
    } else if (authType === AuthTypes.Google) {
      this.auth.getGoogleSignInUrl().subscribe((res: {data: string}) => window.location.href = res.data);
    }
  }
  createPost() {
    if (this.session.getAuthToken()) {
      this.modal.getModal('addPostModal').open();
    } else {
      this.loginUser();
    }
  }
  loginUser() {
    const loginModal = this.modal.getModal('loginModal');
    loginModal.open();
    loginModal.onAnyCloseEventFinished.subscribe(
      (_) => {
        if (this.session.getUser()) {
          this.router.navigate(['add']);
        }
      }
    );
  }
}
