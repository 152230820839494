import {Injectable} from '@angular/core';
import {SessionService} from './session.service';
import {LoginResponse, ResetPassword, UserLogin, UserRegister} from '../models/user';
import {HttpService} from '../http/http.service';
import {tap} from 'rxjs/operators';
import {ProfileService} from '../content/profile.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpService,
              private session: SessionService,
              private profileService: ProfileService) {
  }

  getFBSignInUrl() {
    return this.http.get('/auth/social/facebook');
  }

  loginToFB(queryParams: string) {
    return this.http.get(`/auth/social/facebook/callback${queryParams}`)
      .pipe(
        tap((response: LoginResponse) => {
          this.session.setAuthToken(response.data.token);
          this.session.setUsername(response.data.username);
          this.profileService.getUserProfile().subscribe();
        })
      );
  }

  getGoogleSignInUrl() {
    return this.http.get('/auth/social/google');
  }

  loginToGoogle(queryParams: string) {
    return this.http.get(`/auth/social/google/callback${queryParams}`)
      .pipe(
        tap((response: LoginResponse) => {
          this.session.setAuthToken(response.data.token);
          this.session.setUsername(response.data.username);
          this.profileService.getUserProfile().subscribe();
        })
      );
  }
  login(user: UserLogin) {
    return this.http.post('/auth/login', user)
      .pipe(
        tap((response: LoginResponse) => {
          this.session.setAuthToken(response.data.token);
          this.session.setUsername(response.data.username);
          this.profileService.getUserProfile().subscribe();
        })
      );
  }

  register(user: UserRegister) {
    return this.http.post('/auth/register', user);
  }

  activateAccount(uuid: string) {
    return this.http.post(`/auth/activate/${uuid}`, {});
  }

  requestPasswordReset(email: string) {
    return this.http.post('/auth/password/email', {email});
  }

  resetPassword(resetPassword: ResetPassword) {
    return this.http.post('/auth/password/reset', resetPassword);
  }

  refreshToken() {
    return this.http.post('/auth/refresh', {});
  }

  logout() {
    return this.http.post('/auth/refresh', {});
  }

  getAuthHeaders() {
    return `Bearer ${this.getToken()}`;
  }

  getToken(): string {
    return this.session.getAuthToken();
  }
}
