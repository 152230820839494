import {getDiffDateText} from '../utils/constants';

export class Activity {
  createdAt: string;
  id: number;
  points: number;
  title: string;
  type: ActivityType;
  slug: string;
  name: string;
  diffNowCreated: string;

  constructor(data, name?: string) {
    Object.assign(this, data);
    this.diffNowCreated = getDiffDateText(this.createdAt);
    this.name = name;
  }
}

export enum ActivityType {
  AddedPost = 'userAddedPost',
  DeletedPost = 'userDeletedPost',
  AddedComment = 'userAddedComment',
  DeletedComment = 'userDeletedComment',
  AddedReaction = 'userAddedReaction',
  DeletedReaction = 'userDeletedReaction',
}

export interface ActivityResponse {
  data: Activity;
}

export interface ActivitiesResponse {
  data: Activity[];
}
