import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser, Location} from '@angular/common';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthenticationService} from '../core/authentication/authentication.service';
import {ToastrService} from 'ngx-toastr';
import {distinctUntilChanged, finalize, skip, skipWhile, switchMap} from 'rxjs/operators';
import {PostsService} from '../core/content/posts.service';
import {Pagination, Post} from '../core/models/post';
import {CategoriesService} from '../core/content/categories.service';
import {Subject, Subscription} from 'rxjs';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {ProfileService} from '../core/content/profile.service';
import {DashboardActivity} from '../core/utils/constants';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  posts: Post[] = [];
  pagination: Pagination;
  scrollSubject = new Subject<null>();
  currentCategory: string;
  categorySubscription: Subscription;
  scrollSubscription: Subscription;
  isLoading = false;
  routeChange: Subscription;
  isBrowser: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private authService: AuthenticationService,
              private profileService: ProfileService,
              private toastr: ToastrService,
              private postsService: PostsService,
              private categoriesService: CategoriesService,
              private modal: NgxSmartModalService,
              private location: Location,
              @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    const activity = this.route.snapshot.data.activity;
    const id = this.route.snapshot.params.id;
    if (activity === DashboardActivity.Google || activity === DashboardActivity.Facebook) {
      this.handleSocialLogin();
    } else if (activity === DashboardActivity.Category) {
      this.currentCategory = id;
    } else if (activity === DashboardActivity.Activate) {
      this.authenticateUser(id);
    } else if (activity === DashboardActivity.Reset) {
      this.resetPassword();
    }
    this.handleInitialPostFetching(activity);
    this.handleCategorySelections();
    this.handleScroll();
    this.routeChange = this.router.events
      .pipe()
      .subscribe(e => {
        if (e instanceof NavigationEnd) {
          const query = this.route.snapshot.params.query;
          if (query) {
            this.getPosts('search', query);
          }
        }
    });
  }

  handleInitialPostFetching(activity) {
    if (activity === DashboardActivity.Search) {
      const query =  this.route.snapshot.params.query;
      this.getPosts('search', query);
    } else {
      if (!this.currentCategory) {
        const url = this.route.snapshot.url[0];
        let endpoint;
        if (url && (url.path === 'hot' || url.path === 'trending')) {
          endpoint = url.path;
        }
        this.getPosts(endpoint);
      }
    }
  }

  handleSocialLogin() {
    const activity = this.route.snapshot.data.activity;
    const qp = this.route.snapshot.queryParams;

    if (activity === DashboardActivity.Facebook) {
      this.authService.loginToFB(`?code=${qp.code}&state=${qp.state}`).subscribe(
        res => {
          if (this.isBrowser) {
            this.toastr.success('Prijava uspešna!');
          }
          this.location.go('');
        },
        e => {
          if (this.isBrowser) {
            this.toastr.error('Prijava neuspešna.');
          }
          this.location.go('');
        });
    } else if (activity === DashboardActivity.Google) {
      const query = Object.entries(this.route.snapshot.queryParams).map(el => el.join('=')).join('&');
      this.authService.loginToGoogle(`?${query}`).subscribe(
        res => {
          if (this.isBrowser) {
            this.toastr.success('Prijava uspešna!');
          }
          this.location.go('');
        },
        e => {
          if (this.isBrowser) {
            this.toastr.error('Prijava neuspešna.');
          }
          this.location.go('');
        });
    }
  }

  authenticateUser(id: string) {
    this.authService.activateAccount(id)
      .pipe(
        finalize(() => this.router.navigateByUrl('/'))
      )
      .subscribe(
        () => {
          if (this.isBrowser) {
            this.toastr.success('Uporabnik aktiviran');
          }
          this.modal.getModal('loginModal').open();
        },
        () => this.toastr.error('Aktivacija uporabnika spodletela')
      );
  }

  resetPassword() {
    this.modal.open('resetModal');
  }

  getPosts(endpoint?: string, searchQuery?: string) {
    this.isLoading = true;
    this.postsService.getPosts(0, endpoint, searchQuery)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(postData => {
        this.posts = postData.data;
        this.pagination = postData.pagination;
      });
  }

  loadNewPosts(event) {
    this.scrollSubject.next(null);
  }

  handleEndOfTimeline() {

  }

  handleScroll() {
    this.scrollSubscription = this.scrollSubject.asObservable()
      .subscribe(() => {
        if (this.pagination && this.pagination.totalPages <= this.pagination.currentPage) {
          return this.handleEndOfTimeline();
        }
        const page = this.pagination ? this.pagination.currentPage + 1 : 0;
        const url = this.route.snapshot.url[0];
        const request = this.currentCategory ?
          this.categoriesService.getPostsByCategory(page, this.currentCategory) :
          this.postsService.getPosts(page, (url && (url.path === 'hot' || url.path === 'trending')) ? url.path : undefined);
        this.isLoading = true;
        request
          .pipe(
            finalize(() => this.isLoading = false)
          )
          .subscribe((postsData) => {
            this.posts = this.posts.concat(postsData.data);
            this.pagination = postsData.pagination;
          });
      });
  }

  handleCategorySelections() {
    this.categorySubscription = this.categoriesService.selectedCategorySlug.asObservable().pipe(
      skipWhile(el => !el),
      distinctUntilChanged(),
      switchMap((slug: string) => {
        if (slug) {
          this.isLoading = true;
          this.currentCategory = slug;
        }
        if (slug === 'hot' || slug === 'trending') {
          this.location.go(slug);
          return this.postsService.getPosts(0, slug);
        } else if (slug) {
          this.location.go(`/category/${slug}`);
          return this.categoriesService.getPostsByCategory(0, slug);
        }
      })
    )
      .subscribe(postsData => {
        this.isLoading = false;
        if (this.isBrowser) {
          window.scrollTo(0, 0);
        }
        this.posts = postsData.data;
        this.pagination = postsData.pagination;
      }, error => {
        if (this.isBrowser) {
          this.toastr.error('Prišlo je do težav pri nalaganju vsebine. Prosimo, poskusite kasneje ali pa prijavite napako.')
        }
      });
  }

  ngOnDestroy() {
    this.categorySubscription.unsubscribe();
    this.scrollSubscription.unsubscribe();
    this.routeChange.unsubscribe();
  }
}
