import {Injectable} from '@angular/core';
import {Ranking, RankingPeriod, RankingsResponse} from '../models/ranking';
import {HttpService} from '../http/http.service';
import {map} from 'rxjs/operators';
import {forkJoin} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RankingsService {

  constructor(private http: HttpService) { }

  getRankings(period: RankingPeriod) {
    return this.http.get(`/charts/${period}`)
      .pipe(
        map((ranking: RankingsResponse) => ranking.data.map(rank => new Ranking(rank)))
      );
  }

  getAllRankings() {
    return forkJoin(
      this.getRankings(RankingPeriod.ThisWeek),
      this.getRankings(RankingPeriod.ThisMonth),
      this.getRankings(RankingPeriod.ThisYear),
      this.getRankings(RankingPeriod.PreviousWeek),
      this.getRankings(RankingPeriod.PreviousMonth),
      this.getRankings(RankingPeriod.PreviousYear)
    );
  }
}
