import {AfterViewInit, Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {AdType} from './ad';

declare var iFrameResize;

@Component({
  selector: 'app-ad',
  templateUrl: './ad.component.html',
  styleUrls: ['./ad.component.scss']
})
export class AdComponent implements OnInit {
  @Input() adType = AdType.Post;
  isBrowser = false;
  iframeId = Math.random().toString().substr(2);
  iframeUrl = '';

  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.getAddIframeUrl();
  }

  resizeIframe() {
    iFrameResize({}, `#iframe-${this.iframeId}`);
  }

  getAddIframeUrl() {
    let url = `assets/html/legit-ads`;
    if (this.adType === AdType.Post) {
    } else if (this.adType === AdType.Comment) {
      url += '-comment';
    } else if (this.adType === AdType.Sidebar) {
      url += '-sidebar';
    }
    this.iframeUrl = `${url}.html`;
  }
}
