import {Component, ElementRef, HostListener, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-sidebar-left',
  templateUrl: './sidebar-left.component.html',
  styleUrls: ['./sidebar-left.component.scss']
})
export class SidebarLeftComponent implements OnInit {
  @Input() isProfileVisible = true;
  @Input() isHighScoreVisible = true;
  fixedWidth: number;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.handleFixedContent();
  }

  constructor(private elRef: ElementRef) { }

  ngOnInit() {
    this.handleFixedContent();
  }

  handleFixedContent() {
    this.fixedWidth = this.elRef.nativeElement.parentElement.width - 30;
  }
}
