import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {SessionService} from '../authentication/session.service';
import {ChangePassword, UserProfile, UserResponse} from '../models/user';
import {map, tap} from 'rxjs/operators';
import {forkJoin, Observable} from 'rxjs';
import {CommentsResponse, Post, PostComment, PostsResponse} from '../models/post';
import {ActivitiesResponse, Activity} from '../models/activity';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpService,
              private session: SessionService) {
  }

  getUserProfile(username: string = ''): Observable<UserProfile> {
    return this.http.get(`/profile/${username}`)
      .pipe(
        map((response: UserResponse) => new UserProfile(response.data)),
        tap((user: UserProfile) => {
          if (!username) {
            this.session.setUser(user);
            this.getUserPosition(user.username)
              .subscribe(position => this.session.setUser({...this.session.userProfile.getValue(), position}));
          }
        })
      );
  }

  getWholeUserProfile(username: string = ''): Observable<[UserProfile, Post[], PostComment[], Activity[]]> {
    return forkJoin(
      this.getUserProfile(username),
      this.getUserPosts(username),
      this.getUserComments(username),
      this.getUserActivities(username),
    );
  }

  getUserPosts(username: string) {
    return this.http.get(`/profile/${username}/posts`)
      .pipe(
        map((res: PostsResponse) => res.data.map(post => new Post(post)))
      );
  }

  getUserPosition(username: string) {
    return this.http.get(`/profile/${username}/position`)
      .pipe(
        map((res: {data: {position: number}}) => res.data.position)
      );
  }

  getUserComments(username: string) {
    return this.http.get(`/profile/${username}/comments`)
      .pipe(
        map((res: CommentsResponse) => res.data.map(comment => new PostComment(comment)))
      );
  }

  getUserActivities(username: string) {
    return this.http.get(`/profile/${username}/activities`)
      .pipe(
        map((res: ActivitiesResponse) => res.data.map(activity => new Activity(activity)))
      );
  }

  changeUserAvatar(avatar) {
    const formData = new FormData();
    formData.append('avatar', avatar);
    return this.http.post('/profile/avatar', formData)
      .pipe(
        map((profileResponse: UserResponse) => new UserProfile(profileResponse.data)),
        tap((profile: UserProfile) => this.session.setUser(profile))
      );
  }

  changeUserPassword(changePassword: ChangePassword) {
    return this.http.post('/profile/changePassword', changePassword);
  }

  changeUserName(name: string) {
    return this.http.post('/profile/changeName', {name});
  }

  changeUserAbout(about: string) {
    return this.http.post('/profile/changeAbout', {about});
  }
}
