import {Injectable} from '@angular/core';
import {Report, ReportedResource} from '../models/report';
import {BehaviorSubject} from 'rxjs';
import {HttpService} from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  currentlyReportedResource = new BehaviorSubject<ReportedResource>(null);

  constructor(private http: HttpService) {
  }

  reportResource(report: Report) {
    return this.http.post(`/reports`, report);
  }
}
