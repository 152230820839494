<ngx-smart-modal #addPostModal [closable]="false" [dismissable]="false" identifier="addPostModal">
  <form [formGroup]="postForm" class="js-validation-signin">
    <div class="block block-themed block-rounded block-shadow mb-0">
      <div class="block-header bg-gd-dusk">
        <h3 class="block-title">Dodaj objavo</h3>
        <div class="block-options">
          <button (click)="addPostModal.close()" class="btn-block-option" type="button">
            <i class="si si-close"></i>
          </button>
        </div>
      </div>
      <div class="block-content">
        <div class="form-group row">
          <div class="col-12">
            <label for="post-title">Naslov</label>
            <input class="form-control" formControlName="title" id="post-title" name="post-title" type="text">
            <span class="invalid-feedback" *ngIf="hasError('title', 'required')">To polje je obvezno</span>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12">
            <label for="post-description">Opis</label>
            <textarea class="form-control" formControlName="description" id="post-description" name="post-description"
                      type="text"></textarea>
            <span class="invalid-feedback" *ngIf="hasError('description', 'required')">To polje je obvezno</span>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12">
            <label>Kategorija</label>
            <ng-select [items]="categories"
                       bindLabel="title"
                       bindValue="id"
                       autofocus
                       formControlName="categoryId">
            </ng-select>
            <span class="invalid-feedback" *ngIf="hasError('categoryId', 'required')">To polje je obvezno</span>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12">
            <h6 class="mb-3">Objaviti hočem</h6>
            <div class="btn-group w-100" role="group" aria-label="btn-group">
              <button type="button" [class]="getBtnClass(postTypeBasic.Video)" (click)="setType(postTypeBasic.Video)">Video</button>
              <button type="button" [class]="getBtnClass(postTypeBasic.Image)" (click)="setType(postTypeBasic.Image)">Slika</button>
            </div>
          </div>
        </div>
        <div class="form-group row" *ngIf="!isSectionShown(postTypeBasic.Image)">
          <div class="col-12">
            <label for="post-media-url">URL videa (youtube, facebook ali streamable)</label>
            <input class="form-control" formControlName="mediaUrl" id="post-media-url" name="post-media-url"
                      type="text">
            <span class="invalid-feedback" *ngIf="hasError('mediaUrl', 'required')">To polje je obvezno</span>
            <span class="invalid-feedback" *ngIf="hasError('mediaUrl', 'pattern')">Prilepljen URL ni fb, yt ali streamable video</span>
          </div>
        </div>
        <div class="form-group row" *ngIf="!isSectionShown(postTypeBasic.Video)">
          <div class="col-12">
            <app-file-upload formControlName="mediaImage"></app-file-upload>
            <div class="invalid-feedback" style="display: block">
              <span class="invalid-feedback" *ngIf="hasError('mediaImage', 'required')">To polje je obvezno</span>
              <span class="invalid-feedback" *ngIf="hasError('mediaImage', 'requiredFileType')">Podprti formati: png, jpg, gif</span>
            </div>
          </div>
        </div>
        <div class="form-group row mb-3">
          <!--<div class="col-sm-6 d-sm-flex align-items-center push">
            <div class="custom-control custom-checkbox mr-auto ml-0 mb-0">
              <input class="custom-control-input" id="login-remember-me" name="login-remember-me" type="checkbox">
              <label class="custom-control-label" for="login-remember-me">Remember Me</label>
            </div>
          </div>-->
          <div class="col-sm-12 text-sm-center">
            <button class="btn btn-alt-primary" type="submit" [disabled]="postForm.invalid" (click)="createPost()">
              <i class="si si-cloud-upload mr-10"></i> Objavi
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ngx-smart-modal>
