<!-- Sidebar -->
<nav id="sidebar">
  <!-- Sidebar Content -->
  <div class="sidebar-content">
    <!-- Side Header -->
    <div class="content-header content-header-fullrow bg-black-op-10">
      <div class="content-header-section align-parent justify-content-start position-relative">
        <!-- Logo -->
        <div class="content-header-item">
          <a class="link-effect font-w700 logo-link">
            <img alt="izklop logo" src="assets/img/logo-white.png">
          </a>
        </div>
        <!-- END Logo -->
        <div class="position-absolute close-btn-position">
          <button (click)="showMenu.emit(false)" class="btn-block-option" type="button">
            <i class="fa fa-2x fa-times-circle red-text"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- END Side Header -->

    <!-- Side Main Navigation -->
    <div class="content-side content-side-full">
      <!--
      Mobile navigation, desktop navigation can be found in #page-header

      If you would like to use the same navigation in both mobiles and desktops, you can use exactly the same markup inside sidebar and header navigation ul lists
      -->
      <ul class="nav-main">
        <li>
          <a (click)="showMenu.emit(false)" [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="'active'"
             [routerLink]="['/']"><i class="fa fa-home mr-2"></i>Domov</a>
        </li>
        <li>
          <a (click)="createPost();showMenu.emit(false)" class="pointer"><i class="fa fa-pencil-square-o mr-2"></i>Dodaj</a>
        </li>
        <li>
          <a (click)="showMenu.emit(false)" [routerLinkActive]="'active'" [routerLink]="['/profile']"><i
            class="fa fa-user"></i>Moj Profil</a>
        </li>
        <li>
          <a (click)="handleUserAuth();showMenu.emit(false)" *ngIf="isLoggedIn" class="pointer">
            <i class="fa mr-2 fa-sign-out"></i> Odjava
          </a>
          <a (click)="loginUser();showMenu.emit(false)" *ngIf="!isLoggedIn" class="pointer">
            <i class="fa mr-2 fa-sign-in"></i> Prijava
          </a>
        </li>
      </ul>
      <hr>
      <app-categories></app-categories>
    </div>
    <!-- END Side Main Navigation -->
  </div>
  <!-- Sidebar Content -->
</nav>
<!-- END Sidebar -->


<!-- Header -->
<div id="page-header-wrapper">
  <header id="page-header">
    <!-- Header Content -->
    <div class="content-header">
      <div class="content-header-section w-100">
        <div class="row no-gutters">
          <div class="col-3 text-center d-block d-lg-none">
            <!-- Logo -->
            <div class="content-header-item">
              <a [routerLink]="['/', 'category', '1-liga']" (click)="goToDefaultPosts()" class="font-w700 logo-link">
                <img alt="izklop logo" src="assets/img/logo.png">
              </a>
            </div>
            <!-- END Logo -->
          </div>
          <div class="col text-right text-lg-left">
            <!-- Toggle Sidebar -->
            <!-- Layout API, functionality initialized in Template._uiApiLayout() -->
            <button (click)="showMenu.emit(true)" class="btn btn-dual-secondary d-lg-none p-0 pr-3" data-action="sidebar_toggle"
                    data-toggle="layout" type="button">
              <i class="fa fa-navicon fa-2x"></i>
            </button>
            <!-- END Toggle Sidebar -->

            <!-- Header Navigation -->
            <!--
            Desktop Navigation, mobile navigation can be found in #sidebar

            If you would like to use the same navigation in both mobiles and desktops, you can use exactly the same markup inside sidebar and header navigation ul lists
            If your sidebar menu includes headings, they won't be visible in your header navigation by default
            If your sidebar menu includes icons and you would like to hide them, you can add the class 'nav-main-header-no-icons'
            -->
            <!-- END Header Navigation -->
          </div>
          <div class="col-3 text-center d-none d-lg-block">
            <!-- Logo -->
            <div class="content-header-item">
              <a [routerLink]="['/', 'category', '1-liga']" (click)="goToDefaultPosts()" class="font-w700 logo-link">
                <img alt="izklop logo" src="assets/img/logo.png">
              </a>
            </div>
            <!-- END Logo -->
          </div>
          <div class="col text-right d-none d-lg-block">

          </div>
        </div>
      </div>
    </div>
    <!-- END Header Content -->

    <!-- Header Loader -->
    <div class="overlay-header bg-primary" id="page-header-loader">
      <div class="content-header content-header-fullrow text-center">
        <div class="content-header-item">
          <i class="fa fa-sun-o fa-spin text-white"></i>
        </div>
      </div>
    </div>
    <!-- END Header Loader -->
  </header>
</div>
<!-- END Header -->
