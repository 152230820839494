import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {SessionService} from './session.service';
import {NgxSmartModalService} from 'ngx-smart-modal';


@Injectable()
export class AuthGuard implements CanActivate {
  isBrowser: boolean;
  constructor(private router: Router,
              private session: SessionService,
              private modal: NgxSmartModalService,
              @Inject(PLATFORM_ID) platformId: Object) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean {
    if (!this.session.isLoggedIn) {
      if (this.isBrowser) {
        const loginModal = this.modal.getModal('loginModal');
        loginModal.open();
        const sub = loginModal.onAnyCloseEventFinished.subscribe(
          (_) => {
            if (this.session.isLoggedIn) {
              this.router.navigate([state.url]);
            }
            sub.unsubscribe();
          }
        );
      } else {
        this.router.navigate(['/']);
      }
    }
    return this.session.isLoggedIn;
  }
}
