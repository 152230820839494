<div [style.width.px]="fixedWidth" class="position-sticky overflow-container-side" id="sidebar-right">
  <div class="block block-rounded">
    <div class="block-content block-content-full">
      <button class="btn btn-primary w-100 mb-2" [routerLink]="['/']"><i class="fa fa-home mr-2"></i>Domov</button>
      <div class="input-group mt-2">
        <input [formControl]="searchControl" class="form-control" id="page-header-search-input"
               name="page-header-search-input"
               placeholder="Išči po objavah"
               (keyup.enter)="search()"
               type="text">
        <div class="input-group-append">
          <button (click)="search()" class="btn btn-secondary px-15" type="button">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="block block-rounded">
    <div class="block-content block-content-full">
      <a class="pointer red-text" href="https://forum.hop.si/t/izklop-com" target="_blank">
        <b><i class="si si-microphone"></i> FORUM</b>
      </a>
    </div>
  </div>
  <app-categories (categorySelected)="categorySelected.emit($event)" [isCategoryRedirect]="isCategoryRedirect"></app-categories>
  <app-ad [adType]="'sidebar'"></app-ad>
</div>
