export class ReportedResource {
  reportableId: number;
  reportableType: ReportableType;

  constructor(data) {
    this.reportableId = data.reportableId;
    this.reportableType = data.reportableType;
  }
}

export class Report extends ReportedResource {
  reason: ReportReason;
  comment: string;

  constructor(data) {
    super(data);
    this.reason = data.reason;
    this.comment = data.comment;
  }
}

export enum ReportableType {
  Comment = 'comment',
  Post = 'post'
}

export enum ReportReason {
  Inappropriate = 1,
  Insult,
  Nudity,
  Political,
  Other,
  DoesntWork
}

export const reportReasons = [
  { label: 'Neprimerno', reason: ReportReason.Inappropriate },
  { label: 'Žaljenje', reason: ReportReason.Insult },
  { label: 'Golota', reason: ReportReason.Nudity },
  { label: 'Politično nagovarjanje', reason: ReportReason.Political },
  { label: 'Ostalo', reason: ReportReason.Other },
  { label: 'Ne deluje', reason: ReportReason.DoesntWork }
];
