import {AfterViewInit, Component, HostListener, Inject, PLATFORM_ID, Renderer2} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {SessionService} from './core/authentication/session.service';
import {filter, skip, skipWhile} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  showMenu = false;
  previousScrollPosition = 0;
  isBrowser;

  constructor(@Inject(PLATFORM_ID) platformId: Object,
              private toastr: ToastrService,
              private session: SessionService,
              private renderer: Renderer2,
              private router: Router,
              private titleService: Title,
              @Inject(DOCUMENT) private document: Document) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    this.adjustSidebarScroll(event);
  }

  adjustSidebarScroll(event) {
    const html = document.querySelector('html');
    const sidebar = document.querySelector('#sidebar-right');
    if (!sidebar) return;
    const top = html.scrollTop;
    sidebar.scrollTop = sidebar.scrollTop + (top - this.previousScrollPosition);
    this.previousScrollPosition = top;
  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      this.reloadTrackingScriptOnRouteChange();
      if (localStorage.getItem('hasAcceptedMigrationNotice') !== 'true') {
        const toastr = this.toastr.error('Hey, prenovili smo portal! V primeru, da ste imeli uporabniški račun prosim ponastavite vaše geslo. Hvala!', '', {
          positionClass: 'izklop-migration-notice-position',
          disableTimeOut: true,
          closeButton: true,
        });
        toastr.onHidden.subscribe(() => localStorage.setItem('hasAcceptedMigrationNotice', 'true'));
        this.session.userProfile
          .asObservable()
          .pipe(
            skipWhile(user => !user)
          ).subscribe(() => this.toastr.remove(toastr.toastId));
      }
    }
  }

  reloadTrackingScriptOnRouteChange() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        skip(1)
      ).subscribe(() => {
        this.titleService.setTitle('Izklop');
        setTimeout(() => {
          window['_paq'].push(['setCustomUrl', this.router.url]);
          window['_paq'].push(['setDocumentTitle', this.titleService.getTitle()]);
          window['_paq'].push(['trackPageView']);
        }, 100);
    });
  }
}
