import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class SerializeInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.match(RegExp(/w*\/posts\b/)) && !request.url.includes('avatar')) {
      request = request.clone({
        body: convertBetweenCamelAndSnake(request.body, false),
      });
    }

    return next.handle(request)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse && typeof event.body === 'object') {
            return event.clone({body: convertBetweenCamelAndSnake(event.body, true)});
          } else {
            return event;
          }
        })
      );
  }
}

export const convertBetweenCamelAndSnake = (o: any, isToCamel: boolean) => {
  let newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map((value) => {
      if (typeof value === 'object') {
        value = convertBetweenCamelAndSnake(value, isToCamel);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = isToCamel ?
          origKey.replace(/[-_]([a-z])/g, (g) => g[1].toUpperCase()) :
          origKey.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
        value = o[origKey];
        if (value instanceof Array || (value !== null && value.constructor === Object)) {
          value = convertBetweenCamelAndSnake(value, isToCamel);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
};
