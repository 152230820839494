<div class="block-content block-content-full border-t" [ngClass]="{'pb-0': isSinglePostView}">
  <div class="media">
    <div class="media-body">
      <div class="mb--1 d-flex justify-content-between">
        <div [id]="'title-' + post.slug">
          <p *ngIf="!post.isCommercial">
            <a [routerLink]="['/profile', post.user.username]" class="font-w600 blue">{{post.user.name}}</a>
            <em class="text-muted"> pred {{post.diffNowCreated}}</em> v <em class="text-muted">{{post.categoryNames}}</em>
          </p>
        </div>
        <button (click)="openReportModal(this.post.id, reportableType.Post)"
                *ngIf="session.isLoggedIn && !post.isCommercial" class="btn btn-outline-danger btn-sm h-100 report-button"
                type="button">
          <i class="fa fa-flag"></i>
        </button>
      </div>
      <h1 (click)="routeTo()" [ngClass]="{'pointer': !isSinglePostView}" *ngIf="!post.isCommercial"
          class="ellipsis block-title font-w700 super-big ellipsis-2-line">{{post.title}}</h1>
      <a [href]="post.externalUrl" target="_blank"><h1 *ngIf="post.isCommercial"
          class="ellipsis block-title font-w700 super-big ellipsis-2-line pointer">{{post.title}}</h1></a>
      <div (click)="routeTo()" [ngClass]="{'pointer': !isSinglePostView}" class="no-border-sm" [ngSwitch]="post.postType">
        <div *ngSwitchCase="postType.Image" class="post-content-container">
          <img [src]="post.mediaUrl" alt="post image" class="img-fluid">
        </div>
        <div *ngSwitchCase="postType.Facebook" class="post-content-container">
          <div [attr.data-href]="post.mediaUrl" [id]="'fb-video-' + post.slug" class="fb-video" data-show-text="false"></div>
        </div>
        <div *ngSwitchCase="postType.Youtube" class="video-player-responsive post-content-container">
          <iframe [src]="post.mediaUrl | safe" allowfullscreen frameborder="0" height="315" width="560"></iframe>
        </div>
        <div *ngSwitchCase="postType.Streamable" class="video-player-responsive post-content-container">
          <iframe [src]="post.mediaUrl | safe" allowfullscreen frameborder="0" height="315" width="560"></iframe>
        </div>
        <div *ngSwitchCase="postType.Ad" class="video-player-responsive post-content-container">
          <video controls style="width: 100%;" [muted]="true">
            <source [src]="post.mediaUrl | safe" type="video/mp4">
            Vaš brskalnik ne podpira videa.
          </video>
        </div>
      </div>
      <p [class]="(!isSinglePostView ? 'ellipsis ellipsis-2-line ' : '') + 'text-muted'" *ngIf="!post.isCommercial">{{post.description}}</p>
      <div *ngIf="post.isCommercial" class="ad-description">
        <a [href]="post.externalUrl" target="_blank">{{post.description}}</a>
        <p>Oglas</p>
      </div>
      <div class="stat-counter text-muted" *ngIf="!post.isCommercial">
        <span class="blue-text font-weight-bold">{{post.pointCount | number}}</span> točk
        <span class="blue-text font-weight-bold"> · </span>
        <span class="blue-text font-weight-bold">{{post.views}}</span> ogledov
      </div>
      <div [class]="'social d-flex flex-wrap flex-sm-nowrap ' + (post.isCommercial ? 'justify-content-center' : 'justify-content-between')">
        <div class="social-section social-left" *ngIf="!post.isCommercial">
          <button (click)="react(reactType.Like)"
                  [class]="'element-button like mr-2' + (post.isLikedByUser ? ' active' : '')">
            <i class="fa fa-thumbs-o-up"></i>
          </button>
          <button (click)="react(reactType.Dislike)"
                  [class]="'element-button dislike' + (post.isDislikedByUser ? ' active' : '')">
            <i class="fa fa-thumbs-o-down"></i>
          </button>
        </div>
        <div class="social-section social-middle">
          <ng-container *ngIf="!post.isCommercial">
            <button *ngIf="!isSinglePostView" [routerLink]="['/post', post.slug]"
                    class="btn btn-alt-primary d-none d-sm-block"
                    type="button">
              Komentiraj
            </button>
            <button *ngIf="!isSinglePostView" [routerLink]="['/post', post.slug]"
                    class="element-button comment d-block d-sm-none"
                    type="button">
              <i class="fa fa-commenting-o"></i>
            </button>
          </ng-container>
          <ng-container *ngIf="post.isCommercial">
            <a *ngIf="!isSinglePostView"
               [href]="post.externalUrl"
               target="_blank"
                    class="btn btn-primary"
                    type="button">
              Več info
            </a>
          </ng-container>
        </div>
        <div class="social-section social-right" *ngIf="!post.isCommercial">
          <button (click)="share()" class="element-button fb mr-2"><i class="fa fa-facebook"></i>
          </button>
          <button (click)="copyLink()" class="element-button copy-link"><i class="fa fa-copy"></i></button>
        </div>
      </div>
      <div *ngIf="isSinglePostView" class="comment-section">
        <div class="comments">
          <app-comment (report)="openReportModal($event, reportableType.Comment)" *ngFor="let comment of post.comments"
                       [comment]="comment"></app-comment>
        </div>
        <div class="comment-box mb-4">
          <div class="form-group row mt-4">
            <label class="col-12" for="comment-textarea">Komentiraj</label>
            <div class="col-12">
              <textarea [formControl]="commentControl" class="form-control" id="comment-textarea"
                        name="comment-textarea" placeholder="Vaš komentar gre sem" rows="6"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <button (click)="postComment()" [disabled]="commentControl.invalid" class="btn btn-alt-primary"
                      type="button">
                <i class="fa fa-send mr-10"></i> Pošlji
              </button>
            </div>
          </div>
        </div>
      </div>
      <app-ad *ngIf="isSinglePostView" [adType]="'comment'"></app-ad>
    </div>
  </div>
  <div *ngIf="showAd" class="post-ad-wrapper">
    <app-ad></app-ad>
  </div>
</div>
