import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sidebar-right',
  templateUrl: './sidebar-right.component.html',
  styleUrls: ['./sidebar-right.component.scss']
})
export class SidebarRightComponent implements OnInit {
  @Output() categorySelected = new EventEmitter<string>();
  @Input() isCategoryRedirect = true;
  searchControl = new FormControl();
  fixedWidth: number;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.handleFixedContent();
  }

  constructor(private router: Router,
              private elRef: ElementRef) { }

  ngOnInit() {
    this.handleFixedContent();
  }

  search() {
    this.router.navigate(['/search', this.searchControl.value]);
  }

  handleFixedContent() {
    this.fixedWidth = this.elRef.nativeElement.parentElement.width - 30;
  }
}
