import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ReportsService} from '../core/content/reports.service';
import {Report, reportReasons} from '../core/models/report';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, AfterViewInit {
  reportForm: FormGroup;
  reportReasons = reportReasons;

  constructor(private modal: NgxSmartModalService,
              private reportsService: ReportsService,
              private fb: FormBuilder,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.reportForm = this.fb.group(
      {
        reason: [{value: null, disabled: false}, Validators.required],
        comment: [{value: null, disabled: false}, Validators.required],
      });
  }

  ngAfterViewInit() {
    this.modal.get('reportModal').onAnyCloseEvent.subscribe(() => this.reportForm.reset());
  }

  report() {
    const report = new Report({...this.reportForm.getRawValue(), ...this.reportsService.currentlyReportedResource.getValue()});
    this.reportsService.reportResource(report).subscribe(
      s => {
        this.toastr.success('Prijava vsebine uspešna. V najkrajšem možnem času jo bomo pregledali in se odločili o morebitnem izbrisu.');
        this.modal.close('reportModal');
      },
      e => this.toastr.error('Prijava vsebine ni uspela. Prosimo poskusite znova kasneje.')
    );
  }

  getError(controlName: string) {
    const fc = this.reportForm.get(controlName);
    return fc.touched && fc.invalid;
  }
}
