import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ResetPassword} from '../core/models/user';
import {SessionService} from '../core/authentication/session.service';
import {AuthenticationService} from '../core/authentication/authentication.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {ActivatedRoute} from '@angular/router';
import {matchPassword} from '../core/utils/custom-validators';
import {Location} from '@angular/common';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  resetPasswordInfo: ResetPassword;

  constructor(private session: SessionService,
              private auth: AuthenticationService,
              private fb: FormBuilder,
              private toastr: ToastrService,
              private modal: NgxSmartModalService,
              private route: ActivatedRoute,
              private location: Location) {
  }

  ngOnInit() {
    this.resetForm = this.fb.group(
      {
        email: [{value: null, disabled: false}, Validators.compose([Validators.email, Validators.required])],
        password: [{value: null, disabled: false}, Validators.compose([Validators.required, Validators.minLength(8)])],
        passwordConfirmation: [{value: null, disabled: false}, Validators.compose([Validators.required, Validators.minLength(8)])],
      }, {
        validators: [matchPassword]
      });
  }

  resetPassword() {
    this.resetPasswordInfo = new ResetPassword({
      ...this.resetForm.getRawValue(),
      token: this.route.snapshot.queryParams.token
    });
    this.auth.resetPassword(this.resetPasswordInfo).subscribe(
      (_) => {
        this.toastr.success('Geslo uspešno spremenjeno!');
        this.modal.open('loginModal');
        this.modal.close('resetModal');
        this.location.go('/');
      },
      () => this.toastr.error('Sprememba gesla ni uspela. Poizkusite kasneje ali pa oddajte še en zahtevek')
    );
  }

  openRegister() {
    this.modal.getModal('registerModal').open();
    this.modal.getModal('resetModal').close();
  }

  openForgot() {
    this.modal.getModal('forgotPasswordModal').open();
    this.modal.getModal('resetModal').close();
  }

  getError(controlName: string) {
    const fc = this.resetForm.get(controlName);
    return fc.touched && fc.invalid;
  }

  getPasswordError() {
    const isPasswordError = this.resetForm.hasError('passwordsDontMatch');
    return this.resetForm.get('passwordConfirmation').touched && this.resetForm.get('password').touched && isPasswordError;
  }
}
