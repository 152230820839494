import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {map} from 'rxjs/operators';
import {Page, PageResponse, PagesResponse} from '../models/page';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  constructor(private http: HttpService) {
  }

  getPages() {
    return this.http.get('/pages')
      .pipe(
        map((response: PagesResponse) => response.data.map(page => new Page(page)))
      );
  }

  getPage(id: string) {
    return this.http.get(`/pages/${id}`)
      .pipe(
        map((response: PageResponse) => new Page(response.data))
      );
  }
}
