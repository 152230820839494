import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CommentResponse, Pagination, Post, PostComment, PostCreate, PostResponse, PostsResponse} from '../models/post';
import {map, tap} from 'rxjs/operators';
import {HttpService} from '../http/http.service';
import {PostType, ReactType, toFormData, toResponseBody, uploadProgress} from '../utils/constants';
import {convertBetweenCamelAndSnake} from '../http/serialize.interceptor';
import {AuthenticationService} from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PostsService {

  constructor(private http: HttpService,
              private auth: AuthenticationService) {
  }

  getPosts(page: number = 0, popularCategory?: string, query?: string): Observable<{ data: Post[], pagination: Pagination }> {
    let params = page ? `?page=${page}` : '';
    if (query) {
      if (params) {
        params += `&title=${query}`;
      } else {
        params += `?title=${query}`;
      }
    }
    return this.http.get(`/posts${popularCategory ? `/${popularCategory}` : ''}${params}`)
      .pipe(
        map((res: PostsResponse) => {
          return {
            data: res.data.map(post => new Post(post)),
            pagination: res.meta
          };
        })
      );
  }

  getSinglePost(id: string): Observable<Post> {
    return this.http.get(`/posts/${id}/comments`)
      .pipe(
        map((res: PostResponse) => new Post(res.data)),
      );
  }

  postComment(postId: number, content: string) {
    return this.http.post(`/comments`, {postId, content})
      .pipe(
        map((res: CommentResponse) => new PostComment(res.data))
      );
  }

  createPost(post: PostCreate) {
    const formData: FormData = toFormData(convertBetweenCamelAndSnake(post, false));
    formData.delete(post.postType === PostType.Image ? 'media_url' : 'media_image');
    return this.http.post(`/posts`, formData, {
      reportProgress: true,
      observe: 'events',
    }).pipe(
      uploadProgress(progress => (console.log(progress))),
      toResponseBody(),
      map((res: PostResponse) => new Post(res.data))
    );
  }

  reactToPost(slug: string, reactType: ReactType) {
    return this.http.post(`/posts/${slug}/react/${reactType}`, {});
  }

  incrementViews(slug: string) {
    return this.http.post(`/posts/${slug}/impress`, {});
  }
}
