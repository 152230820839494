import {AfterViewInit, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {SessionService} from '../core/authentication/session.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../core/authentication/authentication.service';
import {ToastrService} from 'ngx-toastr';
import {AuthTypes, UserLogin} from '../core/models/user';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  loginForm: FormGroup;
  userLoginInfo: UserLogin;
  authTypes = AuthTypes;
  hasResetPasswordError = false;
  isBrowser: boolean;

  constructor(private session: SessionService,
              private auth: AuthenticationService,
              private fb: FormBuilder,
              private toastr: ToastrService,
              private modal: NgxSmartModalService,
              @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.loginForm = this.fb.group(
      {
        email: [{value: null, disabled: false}, Validators.compose([Validators.email, Validators.required])],
        password: [{value: null, disabled: false}, Validators.compose([Validators.required, Validators.minLength(8)])],
      });
  }
  ngAfterViewInit() {
    this.modal.get('loginModal').onAnyCloseEvent.subscribe(() => this.hasResetPasswordError = false);
  }
  login() {
    this.userLoginInfo = new UserLogin(this.loginForm.getRawValue());
    this.auth.login(this.userLoginInfo).subscribe(
      (_) => {
        if (this.isBrowser) {
          this.toastr.success('Prijava uspešna!');
        }
        this.modal.getModal('loginModal').close();
      },
      error => {
        if (error && error.message === 'must_reset_password') {
          this.hasResetPasswordError = true;
          if (this.isBrowser) {
            this.toastr.info('Hey, zaradi migracije si morajo uporabniki Izklopa spremeniti geslo. Mail z linkom za spremembo gesla smo ti že poslali na tvoj e-poštni naslov.', '', {
              disableTimeOut: true,
              closeButton: true,
            });
          }
        } else {
          this.hasResetPasswordError = false;
          if (this.isBrowser) {
            this.toastr.error('Vaš email ali geslo nista pravilna. Prosimo, poizkusite ponovno.');
          }
        }
      }
    );
  }
  doAuth(authType: AuthTypes) {
    if (authType === AuthTypes.Facebook) {
      this.auth.getFBSignInUrl().subscribe((res: {data: string}) => window.location.href = res.data);
    } else if (authType === AuthTypes.Google) {
      this.auth.getGoogleSignInUrl().subscribe((res: {data: string}) => window.location.href = res.data);
    }
  }
  openRegister() {
    this.modal.getModal('registerModal').open();
    this.modal.getModal('loginModal').close();
  }
  openForgot() {
    this.modal.getModal('forgotPasswordModal').open();
    this.modal.getModal('loginModal').close();
  }
  getError(controlName: string) {
    const fc = this.loginForm.get(controlName);
    return fc.touched && fc.invalid;
  }
}
