import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Page} from '../core/models/page';
import {DomSanitizer} from '@angular/platform-browser';
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-static',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.scss']
})
export class StaticPageComponent implements OnInit {
  page: Page;
  isBrowser: boolean;
  constructor(private route: ActivatedRoute,
              private sanitizer: DomSanitizer,
              @Inject(PLATFORM_ID) platformId: Object) {
    this.page = this.route.snapshot.data.page;
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    if (this.isBrowser) {
      window.scrollTo({ top: 0});
    }
  }
  get mainContent() {
    return this.sanitizer.bypassSecurityTrustHtml(this.page.mainContent);
  }
}
