import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {Post, PostComment} from '../core/models/post';
import {ProfileService} from '../core/content/profile.service';
import {SessionService} from '../core/authentication/session.service';
import {UserProfile} from '../core/models/user';
import {Activity} from '../core/models/activity';

@Injectable({
  providedIn: 'root'
})
export class ProfileResolver implements Resolve<Observable<[UserProfile, Post[], PostComment[], Activity[]]>> {
  constructor(private profileService: ProfileService,
              private session: SessionService) {
  }

  resolve(routeSnapshot: ActivatedRouteSnapshot) {
    const isOwnProfile = !routeSnapshot.params.id;
    const userId = routeSnapshot.params.id;
    if (!isOwnProfile) {
      return this.profileService.getWholeUserProfile(userId);
    } else {
      const username = this.session.getUsername();
      const user = this.session.getUser();
      if (username || user) {
        return this.profileService.getWholeUserProfile(username ? username : user.username);
      } else {
        return of(null);
      }
    }
  }
}
