import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProfileComponent} from './profile/profile.component';
import {SinglePostComponent} from './single-post/single-post.component';
import {SinglePostResolver} from './single-post/single-post.resolver';
import {AuthGuard} from './core/authentication/auth.guard';
import {ProfileResolver} from './profile/profile.resolver';
import {RankingsComponent} from './rankings/rankings.component';
import {RankingsResolver} from './rankings/rankings.resolver';
import {StaticPageComponent} from './static-page/static-page.component';
import {StaticPageResolver} from './static-page/static-page-resolver.service';

const routes: Routes = [
  {path: 'profile/:id', component: ProfileComponent, resolve: {profileData: ProfileResolver}, runGuardsAndResolvers: 'always'},
  {
    path: 'profile',
    component: ProfileComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    resolve: {profileData: ProfileResolver},
    runGuardsAndResolvers: 'always'
  },
  {path: 'post/:id', component: SinglePostComponent, resolve: {post: SinglePostResolver}},
  {path: 'rankings', component: RankingsComponent, resolve: {rankings: RankingsResolver}},
  {path: 'page/:id', component: StaticPageComponent, resolve: {page: StaticPageResolver}},
  {path: '**', redirectTo: '', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
