<ngx-smart-modal #loginModal [closable]="false" identifier="loginModal">
  <form [formGroup]="loginForm" class="js-validation-signin">
    <div class="block block-themed block-rounded block-shadow mb-0">
      <div class="block-header bg-gd-dusk">
        <h3 class="block-title">Prijavi se</h3>
        <div class="block-options">
          <button (click)="loginModal.close()" class="btn-block-option" type="button">
            <i class="si si-close"></i>
          </button>
        </div>
      </div>
      <div class="block-content">
        <div class="form-group row">
          <div class="col-12">
            <label for="login-email" class="d-block text-center">Prijava s Facebook ali Google</label>
            <div class="d-flex align-items-center justify-content-center">
              <div class="mr-3 text-center">
                <button class="btn btn-circle btn-login-option" type="button" (click)="doAuth(authTypes.Facebook)"><img src="assets/img/facebook.png" alt="user enter with facebook button image"></button>
                <div class="font-size-xs text-muted">Facebook</div>
              </div>
              <div class="text-center">
                <button class="btn btn-circle btn-login-option" type="button" (click)="doAuth(authTypes.Google)"><img src="assets/img/google.png" alt="user enter with google button image"></button>
                <div class="font-size-xs text-muted">Google</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12">
            <label for="login-email">Email</label>
            <input class="form-control" formControlName="email" id="login-email" name="login-email" type="text">
            <span class="error-message" *ngIf="getError('email')">Vnesite veljaven email.</span>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12">
            <label for="login-password">Geslo</label>
            <input class="form-control" formControlName="password" id="login-password" name="login-password"
                   type="password">
            <span class="error-message" *ngIf="getError('password')">Vnesite veljavno geslo.</span>
          </div>
        </div>
        <div class="form-group row" *ngIf="hasResetPasswordError">
          <div class="col-12">
            <p class="text-muted reset-password-notice">
              Zaradi migracije na nov sistem, si morajo obstoječi uporabniki posodobiti geslo. Email z linkom za spremembo gesla smo vam že poslali na vaš e-poštni naslov.
            </p>
          </div>
        </div>
        <div class="form-group row mb-3">
          <div class="col-sm-12 text-sm-center">
            <button class="btn btn-alt-primary" type="submit" (click)="login()" [disabled]="loginForm.invalid">
              <i class="si si-login mr-10"></i> Prijava
            </button>
          </div>
        </div>
      </div>
      <div class="block-content bg-body-light">
        <div class="form-group text-center">
          <a class="link-effect text-muted mr-10 mb-5 d-inline-block" (click)="openRegister()">
            <i class="fa fa-plus mr-5"></i> Nov uporabnik
          </a>
          <a class="link-effect text-muted mr-10 mb-5 d-inline-block" (click)="openForgot()">
            <i class="fa fa-warning mr-5"></i> Pozabljeno geslo
          </a>
        </div>
      </div>
    </div>
  </form>
</ngx-smart-modal>
