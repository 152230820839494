import {getDiffDateText, PostType, ReactType} from '../utils/constants';
import {UserProfile} from './user';

export class Post {
  createdAt: string;
  description: string;
  id: number;
  links: Link[];
  mediaUrl: string;
  reactions: Reaction[];
  slug: string;
  title: string;
  views: number;
  postType: PostType;
  diffNowCreated: string;
  user: UserProfile;
  categories: Category[];
  comments: PostComment[];
  categoryNames: string;
  likes = 0;
  isLikedByUser = false;
  dislikes = 0;
  isDislikedByUser = false;
  ogImage: string;
  isCommercial: boolean;
  externalUrl?: string;

  constructor(data) {
    Object.assign(this, data);
    this.diffNowCreated = getDiffDateText(this.createdAt);
    this.user = data.user ? new UserProfile(data.user.data) : null;
    this.categories = data.categories ? data.categories.data.map(cat => new Category(cat)) : null;
    if (this.categories) {
      this.categoryNames = this.categories.map(category => category.title).join(', ');
    }
    if (this.postType === PostType.Youtube) {
      this.mediaUrl = this.mediaUrl.replace('watch?v=', 'embed/');
      const match = this.mediaUrl.match(youtubeIdRegex);
      if (match && match[2].length == 11) {
        this.mediaUrl = `https://www.youtube.com/embed/${match[2]}`;
      }
    }
    if (this.reactions && this.reactions.length) {
      this.reactions.map(reaction => {
        if (reaction.type === ReactType.Like) {
          this.likes = reaction.count;
          this.isLikedByUser = reaction.reacted;
        } else if (reaction.type === ReactType.Dislike) {
          this.dislikes = reaction.count;
          this.isDislikedByUser = reaction.reacted;
        }
      });
    }
    this.comments = data.comments ? data.comments.data.map(comment => new PostComment(comment)) : null;
    this.ogImage = this.getOgImage(this.postType, this.mediaUrl);
  }

  setUser(user: UserProfile) {
    this.user = user;
  }

  getOgImage(postType, mediaUrl) {
    const defaultImage = 'https://legit.si/wp-content/uploads/2020/03/fb-izk-default.jpg';
    if (postType === PostType.Image) {
      return mediaUrl;
    } else if (postType === PostType.Facebook) {
      const match = facebookIdRegex.exec(mediaUrl);
      const matchNonShare = mediaUrl.match(facebookIdRegexNonShare);
      let finalMatch;
      if (match && match[1]) {
        finalMatch = match[1];
      } else if (matchNonShare && matchNonShare[0]) {
        finalMatch = matchNonShare[0];
      } else {
        finalMatch = defaultImage;
      }
      return `https://graph.facebook.com/${finalMatch}/picture`;
    } else if (postType === PostType.Youtube) {
      const match = mediaUrl.match(youtubeIdRegex);
      if (match && match[2].length == 11) {
        return `https://img.youtube.com/vi/${match[2]}/hqdefault.jpg`;
      } else {
        return defaultImage;
      }
    } else if (postType === PostType.Streamable) {
      return defaultImage;
    }
  }

  get pointCount() {
    return this.likes - this.dislikes;
  }

  react(type: ReactType, isDuplicate: boolean) {
    const isLike = type === ReactType.Like;
    if (isDuplicate) {
      if (isLike) {
        this.likes--;
        this.isLikedByUser = false;
      } else {
        this.dislikes--;
        this.isDislikedByUser = false;
      }
    } else {
      if (isLike) {
        this.likes++;
        if (this.isDislikedByUser) {
          this.dislikes--;
        }
      } else {
        this.dislikes++;
        if (this.isLikedByUser) {
          this.likes--;
        }
      }
      this.isLikedByUser = isLike;
      this.isDislikedByUser = !isLike;
    }
  }
}

export class Reaction {
  type: ReactType;
  count: number;
  reacted: boolean;
}

export class PostCreate {
  title: string;
  postType: PostType;
  description: string;
  categoryId: string;
  mediaUrl?: string;
  mediaImage?: string;

  constructor(data) {
    Object.assign(this, data);
  }
}

export class PostComment {
  id: number;
  isReported: boolean;
  content: string;
  createdAt: string;
  username: string;
  name: string;
  diffNowCreated: string;

  constructor(data) {
    Object.assign(this, data);
    this.diffNowCreated = getDiffDateText(this.createdAt);
  }
}

export interface PostsResponse {
  data: Post[];
  meta: Pagination;
}

export interface PostResponse {
  data: Post;
  meta: Meta;
}

export interface CommentResponse {
  data: PostComment;
}

export interface CommentsResponse {
  data: PostComment[];
  meta: Pagination;
}

export interface Meta {
  pagination: Pagination;
}

export interface Pagination {
  count: number;
  currentPage: number;
  links: string[];
  perPage: number;
  total: number;
  totalPages: number;
}

export class Category {
  iconUrl: string;
  id: number;
  title: string;
  slug: string;
  isHomepage: boolean;
  isVisible: boolean;
  isSpecial: boolean;
  links: Link[];
  posts?: PostsResponse;

  constructor(data) {
    Object.assign(this, data);
  }
}

export interface Link {
  rel: string;
  uri: string;
}

export interface CategoriesResponse {
  data: Category[];
}

export interface CategoryResponse {
  data: Post[];
  meta: Pagination;
}

const youtubeIdRegex = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
const facebookIdRegex = /2F(\d+)%/g;
const facebookIdRegexNonShare = /(\d+)/;
