import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {SessionService} from '../core/authentication/session.service';
import {Router} from '@angular/router';
import {ProfileService} from '../core/content/profile.service';
import {CategoriesService} from '../core/content/categories.service';
import {skip} from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() showMenu = new EventEmitter<boolean>();

  constructor(private modal: NgxSmartModalService,
              private session: SessionService,
              private router: Router,
              private profileService: ProfileService,
              private categoriesService: CategoriesService) {
  }

  get isLoggedIn() {
    return !!this.session.getUser();
  }

  ngOnInit() {
    this.reFetchUser();
    this.categoriesService.selectedCategorySlug
      .pipe(skip(1))
      .subscribe(() => this.showMenu.next(false));
  }

  goToDefaultPosts() {
    this.categoriesService.selectedCategorySlug.next('1-liga');
  }

  loginUser() {
    const loginModal = this.modal.getModal('loginModal');
    loginModal.open();
    loginModal.onAnyCloseEventFinished.subscribe(
      (_) => {
        if (this.session.getUser()) {
          this.router.navigate(['add']);
        }
      }
    );
  }

  handleUserAuth() {
    if (this.isLoggedIn) {
      this.session.destroySession();
    } else {
      this.loginUser();
    }
  }

  createPost() {
    if (this.session.getAuthToken()) {
      this.modal.getModal('addPostModal').open();
    } else {
      this.loginUser();
    }
  }

  private reFetchUser() {
    if (this.session.getAuthToken()) {
      this.profileService.getUserProfile().subscribe();
    }
  }
}
