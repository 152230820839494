import {Component, OnInit} from '@angular/core';
import {Post} from '../core/models/post';
import {ActivatedRoute} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-single-post',
  templateUrl: './single-post.component.html',
  styleUrls: ['./single-post.component.scss']
})
export class SinglePostComponent implements OnInit {
  post: Post;

  constructor(private titleService: Title, private metaService: Meta, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.post = this.route.snapshot.data.post;
    this.setMetaAndTitle();
  }

  setMetaAndTitle() {
    this.titleService.setTitle(this.post.title);
    this.metaService.updateTag({name: 'title', content: this.post.title + ' | Izklop'});
    this.metaService.updateTag({name: 'description', content: this.post.description});
    this.metaService.updateTag({name: 'keywords', content: this.post.title + ', Izklop, Domov'});
    this.metaService.updateTag({property: 'og:title', content: this.post.title + ' | Izklop'});
    this.metaService.updateTag({property: 'og:site_name', content: this.post.title + ' | Izklop'});
    this.metaService.updateTag({property: 'og:type', content: 'article'});
    this.metaService.updateTag({property: 'og:description', content: this.post.description});
    this.metaService.updateTag({property: 'og:image', content: this.post.ogImage});
    this.metaService.updateTag({property: 'og:image:width', content: '1200'});
    this.metaService.updateTag({property: 'og:image:height', content: '630'});
    this.metaService.updateTag({property: 'og:url', content: `https://${environment.domain}/post/${this.post.slug}`});
  }
}
