import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthTypes, UserRegister} from '../core/models/user';
import {SessionService} from '../core/authentication/session.service';
import {AuthenticationService} from '../core/authentication/authentication.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {matchPassword} from '../core/utils/custom-validators';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  userRegisterInfo: UserRegister;
  authTypes = AuthTypes;

  constructor(private session: SessionService,
              private auth: AuthenticationService,
              private fb: FormBuilder,
              private toastr: ToastrService,
              private modal: NgxSmartModalService) {
  }

  ngOnInit() {
    this.registerForm = this.fb.group(
      {
        name: [{value: null, disabled: false}, Validators.compose([Validators.required, Validators.minLength(3)])],
        username: [{value: null, disabled: false}, Validators.compose([Validators.required, Validators.minLength(3)])],
        email: [{value: null, disabled: false}, Validators.compose([Validators.email, Validators.required])],
        password: [{value: null, disabled: false}, Validators.compose([Validators.required, Validators.minLength(8)])],
        passwordConfirmation: [{value: null, disabled: false}, Validators.compose([Validators.required, Validators.minLength(8)])],
        termsAccepted: [{value: false, disabled: false}, Validators.requiredTrue],
      }, {
        validators: [matchPassword]
      });
  }

  register() {
    this.userRegisterInfo = new UserRegister(this.registerForm.getRawValue());
    this.auth.register(this.userRegisterInfo).subscribe(
      (_) => {
        this.toastr.success('Prosim, aktivirajte račun s klikom na link v vašem mailu.', 'Registracija uspešna');
        this.modal.getModal('registerModal').close();
      },
      (e) => this.toastr.error('Registracija uporabnika ni uspela. Prosimo, poskusite kasneje.')
    );
  }

  doAuth(authType: AuthTypes) {
    if (authType === AuthTypes.Facebook) {
      this.auth.getFBSignInUrl().subscribe((res: {data: string}) => window.location.href = res.data);
    } else if (authType === AuthTypes.Google) {
      this.auth.getGoogleSignInUrl().subscribe((res: {data: string}) => window.location.href = res.data);
    }
  }

  openLogin() {
    this.modal.getModal('loginModal').open();
    this.modal.getModal('registerModal').close();
  }

  getError(controlName: string) {
    const fc = this.registerForm.get(controlName);
    return fc.touched && fc.invalid;
  }

  getPasswordError() {
    const isPasswordError = this.registerForm.hasError('passwordsDontMatch');
    return this.registerForm.get('passwordConfirmation').touched && this.registerForm.get('password').touched && isPasswordError;
  }
}
