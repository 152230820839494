import { Injectable } from '@angular/core';
import {map, tap} from 'rxjs/operators';
import {CategoriesResponse, Category, CategoryResponse, Pagination, Post} from '../models/post';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpService} from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  selectedCategorySlug = new BehaviorSubject<string>(null);
  categories = new BehaviorSubject<Category[]>(null);

  constructor(private http: HttpService) {
    this.getCategories().subscribe();
  }

  getCategories(): Observable<Category[]> {
    return this.http.get('/categories')
      .pipe(
        map((response: CategoriesResponse) => {
          return response.data.sort((a, b) => (a.isSpecial === b.isSpecial) ? 0 : a.isSpecial ? -1 : 1);
        }),
        tap((categories) => this.categories.next(categories))
      );
  }

  getPostsByCategory(page: number, categorySlug: string): Observable<{data: Post[], pagination: Pagination}> {
    const params = page ? `?page=${page}` : '';
    return this.http.get(`/categories/${categorySlug}/posts${params}`)
      .pipe(
        map((response: CategoryResponse) => {
          return {
            data: response.data.map(post => new Post(post)),
            pagination: response.meta
          };
        })
      );
  }
}
