import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ProfileComponent} from './profile/profile.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {LoginComponent} from './login/login.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AddPostComponent} from './add-post/add-post.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from './register/register.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {SinglePostComponent} from './single-post/single-post.component';
import {DashboardModule} from './dashboard/dashboard.module';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {RankingsComponent} from './rankings/rankings.component';
import {StaticPageComponent} from './static-page/static-page.component';
import {ReportComponent} from './report/report.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {Location} from '@angular/common';

export function translateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    AddPostComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    SinglePostComponent,
    RankingsComponent,
    StaticPageComponent,
    ReportComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'www' }),
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    NgxSmartModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    NgbModule,
    DashboardModule,
    AppRoutingModule,
    NgSelectModule,
    ToastrModule.forRoot(),
    LoadingBarRouterModule,
    InfiniteScrollModule,
    // LoadingBarHttpClientModule,
    // LoadingBarModule,
    // SocialLoginModule,
  ],
  providers: [{provide: 'location', useValue: Location}],
  bootstrap: [AppComponent]
})
export class AppModule {
}
