<div class="row">
  <div class="col-lg-3 d-none d-lg-block">
    <app-sidebar-left></app-sidebar-left>
  </div>

  <div class="col-lg-6 no-padding-sm">
    <div class="block block-rounded">
      <div class="block-content block-content-full bg-primary-light d-flex justify-content-between align-items-center flex-wrap">
        <p class="text-white my-1">Objavi smešni video ali sliko in se poteguj za nagrado</p>
        <button class="btn btn-primary border-0 font-w700 font-size-sm my-1" type="button" [routerLink]="['/page', 'lestvica-navodila']">
          <i class="fa fa-pencil-square-o mr-5"></i> VEČ INFO
        </button>
      </div>
      <app-post [post]="post" *ngIf="post" [isSinglePostView]="true"></app-post>
    </div>
  </div>

  <div class="col-lg-3 d-none d-lg-block">
    <app-sidebar-right></app-sidebar-right>
  </div>
</div>
