<!-- Footer -->
<footer class="bg-white opacity-0 d-none" id="page-footer">
  <div class="content py-20 font-size-xs clearfix">
    <div class="float-right">
      Crafted with <i class="fa fa-heart text-pulse"></i> by <a class="font-w600" href="https://1.envato.market/ydb"
                                                                target="_blank">pixelcave</a>
    </div>
    <div class="float-left">
      <a class="font-w600" href="https://1.envato.market/95j" target="_blank">Codebase 3.1</a> &copy; <span
      class="js-year-copy"></span>
    </div>
  </div>
</footer>
<!-- END Footer -->
