import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CookieService} from 'ngx-cookie-service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from './http/auth.interceptor';
import {SerializeInterceptor} from './http/serialize.interceptor';
import { SafePipe } from './utils/safe.pipe';
import {AuthGuard} from './authentication/auth.guard';

@NgModule({
  declarations: [SafePipe],
  imports: [
    CommonModule,
  ],
  exports: [
    SafePipe
  ],
  providers: [CookieService, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: SerializeInterceptor,
    multi: true
  },
  AuthGuard]
})
export class CoreModule {
}
