<div [style.width.px]="fixedWidth" class="position-sticky overflow-container-side">
  <app-profile-preview *ngIf="isProfileVisible"></app-profile-preview>
  <app-highscore *ngIf="isHighScoreVisible"></app-highscore>
  <div class="block block-rounded">
    <div class="block-content block-content-full text-muted font-size-sm">
      <a class="ml-5 d-block" href="https://www.facebook.com/izklop/" target="_blank">Izklop na Facebooku</a>
      <a class="ml-5 d-block" href="https://www.instagram.com/izklopcom/" target="_blank">Izklop na Instagramu</a>
      <a class="ml-5 d-block" [routerLink]="['/page', 'lestvica-navodila']">Lestvica navodila</a>
      <hr/>
      <a class="ml-5 d-block" [routerLink]="['/page', 'splosni-pogoji']">Splošni Pogoji</a>
      <a class="ml-5 d-block" [routerLink]="['/page', 'piskotki']">Piškotki</a>
    </div>
    <div class="block-content block-content-full border-t">
      <a href="https://legit.si/oglasevanje-na-portalih/" target="_blank">
        <i class="fa fa-external-link-square mr-5"></i> Oglašujte z nami
      </a>
      <hr/>
      <p class="mb-0 red-text">Partnerske povezave</p>
      <a class="d-block" href="https://www.autodoc.si/" target="_blank">www.AutoDock.si</a>
      <a class="d-block" href="https://rezervniavtodeli24.si " target="_blank">www.rezervniavtodeli24.si </a>
    </div>
  </div>
</div>
