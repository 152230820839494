import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DashboardRoutingModule} from './dashboard-routing.module';
import {DashboardComponent} from './dashboard.component';
import { ProfilePreviewComponent } from './profile-preview/profile-preview.component';
import {SharedModule} from '../shared/shared.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {SidebarLeftComponent} from './common/sidebar-left/sidebar-left.component';
import {SidebarRightComponent} from './common/sidebar-right/sidebar-right.component';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [DashboardComponent, ProfilePreviewComponent, SidebarLeftComponent, SidebarRightComponent],
  exports: [
    ProfilePreviewComponent,
    SidebarLeftComponent,
    SidebarRightComponent
  ],
    imports: [
        CommonModule,
        DashboardRoutingModule,
        SharedModule,
        InfiniteScrollModule,
        ReactiveFormsModule,
    ]
})
export class DashboardModule {
}
