import {Link} from './post';

export class Ranking {
  type: RankingPeriod;
  username: string;
  name: string;
  points: number;
  dateFrom: string;
  dateTo: string;
  links: Link[];

  constructor(data) {
    Object.assign(this, data);
  }
}

export enum RankingPeriod {
  ThisWeek = 'thisWeek',
  ThisMonth = 'thisMonth',
  ThisYear = 'thisYear',
  PreviousWeek = 'lastWeek',
  PreviousMonth = 'lastMonth',
  PreviousYear = 'lastYear'
}

export interface RankingsResponse {
  data: Ranking[];
}
