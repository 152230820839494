<div class="block block-rounded">
  <div class="block-content block-content-full">
    <ul class="nav-users pull-all cat-ul">
      <li *ngFor="let category of popularCategories; let i = index">
        <a class="text-muted pointer" (click)="selectCategory(i, true)" [ngClass]="{'selected-category': selectedPopularCategory === i}">
          <b><i [class]="'si ' + category.icon"></i> {{category.label}}</b>
        </a>
      </li>
    </ul>
  </div>
</div>
<div class="block block-rounded">
  <div class="block-content block-content-full mt-4">
    <ng-container *ngIf="categories;else loading">
      <ul class="nav-users pull-all cat-ul">
        <li *ngFor="let category of categories; let i = index">
          <a (click)="selectCategory(i)" [ngClass]="{'selected-category': selectedCategoryIndex.getValue() === i && selectedPopularCategory == null}"
             class="text-muted"
             href="javascript:void(0)"
          >
            <img [alt]="category.title + ' icon'" [src]="category.iconUrl" class="category-icon">{{category.title}}
          </a>
        </li>
      </ul>
    </ng-container>
    <ng-template #loading>
      <div class="d-flex align-items-center justify-content-center">
        <app-spinner></app-spinner>
      </div>
    </ng-template>
  </div>
</div>
