<ngx-smart-modal #forgotPasswordModal [closable]="false" identifier="forgotPasswordModal">
  <form [formGroup]="forgotForm" class="js-validation-signin">
    <div class="block block-themed block-rounded block-shadow mb-0">
      <div class="block-header bg-gd-primary">
        <h3 class="block-title">Zahtevek za spremembo gesla</h3>
        <div class="block-options">
          <button (click)="forgotPasswordModal.close()" class="btn-block-option" type="button">
            <i class="si si-close"></i>
          </button>
        </div>
      </div>
      <div class="block-content">
        <div class="form-group row">
          <div class="col-12">
            <label for="reminder-credential">Email</label>
            <input type="text" class="form-control" id="reminder-credential" name="reminder-credential" formControlName="email">
            <span class="error-message" *ngIf="getError('email')">Vnesite veljaven email.</span>
          </div>
        </div>
        <div class="form-group text-center mb-3">
          <button type="submit" class="btn btn-alt-primary" (click)="requestPasswordReset()">
            <i class="fa fa-asterisk mr-10"></i> Pošlji
          </button>
        </div>
      </div>
      <div class="block-content bg-body-light">
        <div class="form-group text-center">
          <a class="link-effect text-muted mr-10 mb-5 d-inline-block" (click)="openLogin()">
            <i class="fa fa-user text-muted mr-5"></i> Prijava
          </a>
        </div>
      </div>
    </div>
  </form>
</ngx-smart-modal>
