<ngx-smart-modal #reportModal identifier="reportModal" [closable]="false">
  <form [formGroup]="reportForm" class="js-validation-signup">
    <div class="block block-themed block-rounded block-shadow mb-0">
      <div class="block-header bg-danger">
        <h3 class="block-title">Prijava vsebine</h3>
        <div class="block-options">
          <button (click)="reportModal.close()" class="btn-block-option" type="button">
            <i class="si si-close"></i>
          </button>
        </div>
      </div>
      <div class="block-content">
        <div class="form-group row">
          <div class="col-12">
            <label for="reason">Razlog</label>
            <ng-select [items]="reportReasons"
                       bindLabel="label"
                       bindValue="reason"
                       autofocus
                       id="reason"
                       formControlName="reason">
            </ng-select>
            <span *ngIf="getError('reason')" class="error-message">Pred prijavo objave ali komentarja morate izbrati veljaven razlog.</span>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12">
            <label for="report-comment">Dodatno pojasnilo</label>
            <textarea class="form-control" formControlName="comment" id="report-comment" name="report-comment"
                      type="text"></textarea>
            <span *ngIf="getError('comment')" class="error-message">Vnesite dodatno pojasnilo.</span>
          </div>
        </div>
        <div class="form-group row mb-3">
          <div class="col-sm-12 text-sm-center">
            <button (click)="report()" [disabled]="reportForm.invalid" class="btn btn-alt-danger" type="submit">
              <i class="fa fa-ban mr-10"></i> Prijavi vsebino
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ngx-smart-modal>
