<div id="page-container" class="sidebar-inverse side-scroll page-header-fixed main-content-boxed" [ngClass]="{'sidebar-o-xs': showMenu}">
  <ngx-loading-bar></ngx-loading-bar>
  <app-header (showMenu)="showMenu = $event"></app-header>
  <!-- Main Container -->
  <main id="main-container">
    <!-- Page Content -->
    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <!-- END Page Content -->
  </main>
  <!-- END Main Container -->
  <app-footer></app-footer>
  <app-add-post></app-add-post>
  <app-report></app-report>
  <app-login></app-login>
  <app-register></app-register>
  <app-reset-password></app-reset-password>
  <app-forgot-password></app-forgot-password>
</div>
