<ngx-smart-modal #registerModal [closable]="false" identifier="registerModal" [dismissable]="false">
  <form [formGroup]="registerForm" class="js-validation-signup">
    <div class="block block-themed block-rounded block-shadow mb-0">
      <div class="block-header bg-gd-emerald">
        <h3 class="block-title">Vnesite svoje podatke</h3>
        <div class="block-options">
          <button (click)="registerModal.close()" class="btn-block-option" type="button">
            <i class="si si-close"></i>
          </button>
        </div>
      </div>
      <div class="block-content">
        <div class="form-group row">
          <div class="col-12">
            <label class="d-block text-center">Prijava s Facebook ali Google</label>
            <div class="d-flex align-items-center justify-content-center">
              <div class="mr-3 text-center">
                <button class="btn btn-circle btn-login-option" type="button" (click)="doAuth(authTypes.Facebook)"><img src="assets/img/facebook.png" alt="user enter with facebook button image"></button>
                <div class="font-size-xs text-muted">Facebook</div>
              </div>
              <div class="text-center">
                <button class="btn btn-circle btn-login-option" type="button" (click)="doAuth(authTypes.Google)"><img src="assets/img/google.png" alt="user enter with google button image"></button>
                <div class="font-size-xs text-muted">Google</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12">
            <label for="signup-username">Ime in priimek</label>
            <input class="form-control" id="signup-name" name="signup-name" placeholder="Janez Novak"
                   type="text" formControlName="name">
            <span class="error-message" *ngIf="getError('name')">Ime in priimek morata vsebovati vsaj 3 znake.</span>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12">
            <label for="signup-username">Uporabniško ime</label>
            <input class="form-control" id="signup-username" name="signup-username" placeholder="j4n3z_n0v4k"
                   type="text" formControlName="username">
            <span class="error-message" *ngIf="getError('username')">Uporabniško ime mora vsebovati vsaj 3 znake.</span>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12">
            <label for="signup-email">Email</label>
            <input class="form-control" id="signup-email" name="signup-email" placeholder="janez@novak.com"
                   type="email" formControlName="email">
            <span class="error-message" *ngIf="getError('email')">Vnesite veljaven email.</span>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12">
            <label for="signup-password">Geslo</label>
            <input class="form-control" id="signup-password" name="signup-password" placeholder="********"
                   type="password" formControlName="password">
            <span class="error-message" *ngIf="getError('password')">Vnesite vsaj 8 znakov dolgo geslo.</span>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12">
            <label for="signup-password-confirm">Ponovite geslo</label>
            <input class="form-control" id="signup-password-confirm" name="signup-password-confirm" placeholder="********"
                   type="password" formControlName="passwordConfirmation">
            <span class="error-message" *ngIf="getPasswordError()">Gesli se ne ujemata.</span>
          </div>
        </div>
        <div class="form-group row mb-3">
          <div class="col-sm-12 d-sm-flex align-items-center push">
            <div class="custom-control custom-checkbox mr-auto ml-0 mb-0">
              <input class="custom-control-input" id="termsAccepted" name="termsAccepted" type="checkbox" formControlName="termsAccepted">
              <label class="custom-control-label" for="termsAccepted">Sprejemam&nbsp;</label>
              <span class="blue-text pointer" [routerLink]="['/page', 'splosni-pogoji']">pogoje uporabe</span>
            </div>
          </div>
        </div>
        <div class="form-group row mb-3">
          <div class="col-sm-12 text-sm-center">
            <button class="btn btn-alt-success" type="submit" (click)="register()" [disabled]="registerForm.invalid">
              <i class="fa fa-plus mr-10"></i> Ustvari račun
            </button>
          </div>
        </div>
      </div>
      <div class="block-content bg-body-light">
        <div class="form-group text-center">
          <!--<a class="link-effect text-muted mr-10 mb-5 d-inline-block" data-target="#modal-terms" data-toggle="modal"
             href="#">
            <i class="fa fa-book text-muted mr-5"></i> Read Terms
          </a>-->
          <a class="link-effect text-muted mr-10 mb-5 d-inline-block" (click)="openLogin()">
            <i class="fa fa-user text-muted mr-5"></i> Prijava
          </a>
        </div>
      </div>
    </div>
  </form>
</ngx-smart-modal>
