import {CommentsResponse, Pagination, Post, PostsResponse} from './post';
import {ActivitiesResponse} from './activity';

export interface UserResponse {
  data: UserProfile;
}

export class UserLogin {
  email: string;
  password: string;

  constructor(data: UserLogin) {
    Object.assign(this, data);
  }
}

export class ChangePassword {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;

  constructor(data: UserLogin) {
    Object.assign(this, data);
  }
}

export interface LoginResponse {
  data: {
    username: string;
    token: string;
    tokenType: string;
    expiresIn: number;
  };
}

export class UserRegister {
  name: string;
  email: string;
  about: string;
  username: string;
  password: string;
  passwordConfirmation: string;
  termsAccepted: boolean;

  constructor(data: UserRegister) {
    Object.assign(this, data);
  }
}

export class ResetPassword {
  email: string;
  password: string;
  passwordConfirmation: string;
  token: string;

  constructor(data: ResetPassword) {
    Object.assign(this, data);
  }
}

export class UserProfile {
  username: string;
  name: string;
  about: string;
  avatar: any;
  email: string;
  verified: boolean;
  createdAt: Date;
  points: number;
  position: number;
  posts: Post[] | { data: Post[], meta: Pagination };

  constructor(userProfile) {
    Object.assign(this, userProfile);
    if (!this.avatar) {
      this.avatar = 'assets/img/user.png';
    }
  }
}

export enum AuthTypes {
  Login,
  Register,
  Facebook,
  Google,
  Logout
}
