<div class="row">
  <div class="col-lg-3 d-none d-lg-block">
    <app-sidebar-left></app-sidebar-left>
  </div>

  <div class="col-lg-6">
    <div class="block block-rounded">
      <div class="block-content block-content-full bg-primary-light d-flex justify-content-between align-items-center flex-wrap">
        <p class="text-white my-1">Objavi smešni video ali sliko in se poteguj za nagrado</p>
        <button class="btn btn-primary border-0 font-w700 font-size-sm my-1" type="button" [routerLink]="['/page', 'lestvica-navodila']">
          <i class="fa fa-pencil-square-o mr-5"></i> VEČ INFO
        </button>
      </div>
      <div (scrolled)="loadNewPosts($event)"
           [infiniteScrollDistance]="2"
           [infiniteScrollThrottle]="50"
           class="posts-results"
           infiniteScroll
           id="scrollContainer"
      >
        <app-post *ngFor="let post of posts; let i = index;" [post]="post" [showAd]="i % 5 === 4"></app-post>
      </div>
      <div *ngIf="isLoading" class="d-flex w-100 justify-content-center py-30">
        <app-spinner></app-spinner>
      </div>
      <div *ngIf="!isLoading && this.pagination?.total !== posts?.length"
           class="w-100 d-flex justify-content-center p-3">
        <button (click)="loadNewPosts($event)" class="btn btn-alt-primary d-block pl-5 pr-5 py-3" type="button">
          Naloži nove
        </button>
      </div>
      <div *ngIf="!isLoading && this.pagination?.total === posts?.length"
           class="p-4 d-flex justify-content-center font-size-h6">
        Čestitke. Prišli ste do konca kategorije!
      </div>
    </div>
  </div>

  <div class="col-lg-3 d-none d-lg-block">
    <app-sidebar-right [isCategoryRedirect]="false"></app-sidebar-right>
  </div>
</div>
