import {Category, Link, PostsResponse} from './post';

export class Page {
  id: number;
  title: string;
  slug: string;
  shortContent: string;
  mainContent: string;
  createdAt: string;
  updatedAt: string;
  links: Link[];

  constructor(data) {
    Object.assign(this, data);
  }
}

export interface PageResponse {
  data: Page;
}

export interface PagesResponse {
  data: Page[];
}
