import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  get(endpoint) {
    return this.http.get(environment.apiUrl + endpoint);
  }
  post(endpoint, data, options = {}) {
    return this.http.post(environment.apiUrl + endpoint, data, options);
  }
}
